import React from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';

const ProtectedLayout = ({ children }) => {
    const location = useLocation();
    const isMapPage = location.pathname === '/find-gym';

    return (
        <>
            <Header 
                toggleSidebar={isMapPage ? children.props.toggleSidebar : undefined}
                isSidebarOpen={isMapPage ? children.props.isSidebarOpen : undefined}
            />
            {children}
        </>
    );
};

export default ProtectedLayout;
