// src/services/feedService.js
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';

export const fetchUserFeed = async (userId) => {
    try {
        const userDoc = await getDoc(doc(firestore, 'users', userId));
        const followingUsers = userDoc.data()?.following || [];

        let feedData = [];

        const activitiesSnapshot = await getDocs(collection(firestore, 'activities'));
        activitiesSnapshot.forEach(activityDoc => {
            const activityData = activityDoc.data();
            const activityUserId = activityData.userId;

            if (followingUsers.includes(activityUserId)) {
                feedData.push({
                    type: activityData.type,
                    data: activityData,
                });
            }
        });

        console.log('Fetched Feed Data:', feedData);

        // Sort the feed by timestamp
        feedData.sort((a, b) => b.data.timestamp - a.data.timestamp);

        return feedData;
    } catch (error) {
        console.error('Error fetching feed data:', error);
        throw error;
    }
};
