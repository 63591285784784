import React, { useState, useEffect, useRef, useContext } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../common/LoadingOverlay';
import Sidebar from './Sidebar';
import '../../styles/styles.css';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../services/firebase';
import { MapContext } from '../../context/MapContext';
import { useLoadScript } from '@react-google-maps/api';
import LocationDeniedModal from './LocationDeniedModal';
import EditProfileModal from './EditProfileModal';
import { useAuthState } from 'react-firebase-hooks/auth';

const libraries = ['places', 'geometry'];

const countryUsingMiles = ['United States', 'Liberia', 'Myanmar', 'United Kingdom'];

const isMobileSafari = () => {
    return (
        navigator.userAgent.match(/Safari/i) &&
        navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
        !navigator.userAgent.match(/Chrome/i)
    );
};

const MapContainer = ({ isSidebarOpen, toggleSidebar }) => {
    const [user] = useAuthState(auth); // Get the logged-in user
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,  // Include the updated libraries array
    });

    const {
        selectedPosition, setSelectedPosition,
        selectedPlace, setSelectedPlace,
        previousPosition, setPreviousPosition,
        previousSelectedPlace, setPreviousSelectedPlace
    } = useContext(MapContext);

    const [places, setPlaces] = useState([]);
    const [mapMarkers, setMapMarkers] = useState([]);
    const [searchDistance, setSearchDistance] = useState(5); // default to 5 miles
    const [searchTerms, setSearchTerms] = useState('BJJ'); // default search term
    const [loading, setLoading] = useState(true);
    const [locationDenied, setLocationDenied] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null); // Immutable current location
    const [distanceUnit, setDistanceUnit] = useState('km'); // Default to km
    const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
    const [profile, setProfile] = useState(null); // State to hold the user's profile
    const mapRef = useRef(null);
    const navigate = useNavigate();

    // Check if the user's profile is complete when the component mounts
    useEffect(() => {
        let isMounted = true; // flag to check if the component is still mounted

        const checkUserProfile = async () => {
            if (user && isMounted) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (!userData.city) { // If the city field is missing, the profile is incomplete
                        setProfile(userData);
                        setIsProfileIncomplete(true);
                    }
                }
            }
        };

        checkUserProfile();

        return () => {
            isMounted = false; // Clean up the isMounted flag on component unmount
        };
    }, [user]);

    useEffect(() => {
        if (currentLocation) {
            fetchCountry(currentLocation.lat, currentLocation.lng);
        }
    }, [currentLocation]);

    const fetchCountry = async (lat, lng) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);

            if (!response.ok) {
                console.error('Failed to fetch country data:', response.statusText);
                return;
            }

            const data = await response.json();

            if (data.results && data.results.length > 0) {
                const country = data.results[0].address_components.find(comp => comp.types.includes('country')).long_name;
                if (countryUsingMiles.includes(country)) {
                    setDistanceUnit('miles');
                } else {
                    setDistanceUnit('km');
                }
            } else {
                console.warn('No results found in geocoding data');
            }
        } catch (error) {
            console.error('Error fetching country:', error);
        }
    };

    const performSearch = (position) => {
        if (!window.google || !window.google.maps || !mapRef.current) {
            console.error('Google Maps API not loaded or map reference is missing');
            return;
        }

        const service = new window.google.maps.places.PlacesService(mapRef.current);
        const request = {
            location: position,
            radius: searchDistance * 1609.34, // convert miles to meters
            keyword: searchTerms.split(',').map((term) => term.trim()).join('|'), // convert search terms to a pipe-separated string
        };

        service.nearbySearch(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setPlaces(results);
            } else {
                console.error('Nearby search was not successful for the following reason: ' + status);
            }
        });
    };

    const handleSelectPlace = async (place) => {
        const placeId = place.place_id || place.id;
        const lat = typeof place.geometry.location.lat === 'function' ? place.geometry.location.lat() : place.geometry.location.lat;
        const lng = typeof place.geometry.location.lng === 'function' ? place.geometry.location.lng() : place.geometry.location.lng;

        try {
            const placeDocRef = doc(firestore, 'locations', placeId);
            const placeDoc = await getDoc(placeDocRef);
            if (placeDoc.exists()) {
                const data = placeDoc.data();
                setSelectedPlace({ ...data, id: placeDoc.id, lat, lng });
            } else {
                setSelectedPlace({ ...place, id: placeId, lat, lng });
            }
        } catch (error) {
            console.error('Error fetching document:', error);
            setSelectedPlace({ ...place, id: placeId, lat, lng });
        }
        toggleSidebar();
    };

    const handleUseCurrentLocation = () => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newPosition = { lat: latitude, lng: longitude };
                    setCurrentLocation(newPosition);
                    setSelectedPosition(newPosition);
                    if (mapRef.current) {
                        mapRef.current.panTo(newPosition);
                    }
                    if (isLoaded) {
                        performSearch(newPosition);
                    }
                    setLoading(false);
                },
                (error) => {
                    console.error('Error fetching current location:', error);
                    setLoading(false);
                    if (isMobileSafari()) {
                        setLocationDenied(true);
                    }
                },
                { timeout: 20000, enableHighAccuracy: false } // Adjust timeout and accuracy settings
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const requestLocationPermission = () => {
            if (navigator.permissions) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    if (isMounted) {
                        if (result.state === 'granted') {
                            handleUseCurrentLocation();
                        } else if (result.state === 'prompt') {
                            handleUseCurrentLocation();
                        } else if (result.state === 'denied') {
                            console.warn('Geolocation permissions are denied');
                            setLoading(false);
                            if (isMobileSafari()) {
                                setLocationDenied(true);
                            }
                        }
                        result.onchange = () => {
                            if (isMounted && result.state === 'granted') {
                                handleUseCurrentLocation();
                            }
                        };
                    }
                });
            } else if (isMounted) {
                handleUseCurrentLocation();
            }
        };

        if (isLoaded) {
            requestLocationPermission();
        }

        return () => {
            isMounted = false;
        };
    }, [isLoaded]);

    useEffect(() => {
        let isMounted = true;

        const fetchMarkers = async () => {
            const snapshot = await getDocs(collection(firestore, 'locations'));
            const markers = snapshot.docs
                .map((doc) => ({ id: doc.id, ...doc.data() }))
                .filter((doc) => doc.locationSaved);
            if (isMounted) {
                setMapMarkers(markers);
            }
        };
        fetchMarkers();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleMapClick = (event) => {
        const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setSelectedPosition(position);
        if (isLoaded) {
            performSearch(position);
        }
        if (!isSidebarOpen) {
            toggleSidebar(); // Open the sidebar when the map is clicked
        }
    };

    const handleMarkerDragEnd = (e) => {
        const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setSelectedPosition(position);
        if (isLoaded) {
            performSearch(position);
        }
        if (!isSidebarOpen) {
            toggleSidebar(); // Open the sidebar when the marker is dragged
        }
    };

    const handleViewGymInfo = (place) => {
        const lat = place.geometry?.location.lat();
        const lng = place.geometry?.location.lng();
        const placeId = place.place_id || place.id;
        const name = place.name;
        const address = place.vicinity || place.formatted_address;

        setPreviousPosition(selectedPosition);
        setPreviousSelectedPlace(selectedPlace);
        navigate(`/gym/${placeId}`, {
            state: { placeId, lat, lng, name, address }
        });
    };

    const handleSavedMarkerClick = async (id) => {
        try {
            const placeDocRef = doc(firestore, 'locations', id);
            const placeDoc = await getDoc(placeDocRef);
            if (placeDoc.exists()) {
                const data = placeDoc.data();
                setSelectedPlace({ ...data, id: placeDoc.id, lat: data.lat, lng: data.lng });
                if (mapRef.current) {
                    mapRef.current.panTo({ lat: data.lat, lng: data.lng });
                }
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const customMarkerIcon = {
        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        scaledSize: isLoaded && window.google ? new window.google.maps.Size(50, 50) : null,
    };

    if (loadError) {
        console.error("Error loading Google Maps:", loadError);
        return <div>Error loading maps. Please try again later.</div>;
    }

    if (!isLoaded) {
        return <LoadingOverlay />;
    }

    const closeLocationDeniedModal = () => {
        setLocationDenied(false);
    };

    const convertMeters = (distance, unit) => {
        if (unit === 'miles') {
            return (distance / 1609.34).toFixed(2); // Convert meters to miles
        } else {
            return (distance / 1000).toFixed(2); // Convert meters to kilometers
        }
    };

    return (
        <div className="body-content">
            <div className="container">
                {locationDenied && isMobileSafari() && (
                    <LocationDeniedModal onClose={closeLocationDeniedModal} />
                )}
                <Sidebar
                    isOpen={isSidebarOpen}
                    places={places}
                    onSelectPlace={handleSelectPlace}
                    toggleSidebar={toggleSidebar}
                    onUseCurrentLocation={handleUseCurrentLocation} // Pass the function to Sidebar
                />
                <button
                    className="hamburger-button"
                    onClick={toggleSidebar}
                    aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
                >
                    ☰
                </button>
                <div className="map-container">
                    {loading && <LoadingOverlay />}
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                            aspectRatio: '1 / 1',
                        }}
                        zoom={12}
                        center={selectedPosition || previousPosition}
                        onClick={handleMapClick}
                        onLoad={(map) => {
                            if (map) {
                                mapRef.current = map;
                                setTimeout(() => {
                                    if (previousPosition) {
                                        map.panTo(previousPosition);
                                    }
                                }, 500); // Small delay to ensure the map has fully loaded before panning
                            } else {
                                console.error('Failed to load map');
                            }
                        }}
                    >
                        <Marker
                            position={selectedPosition || previousPosition} // Movable marker
                            icon={customMarkerIcon}
                            draggable
                            onDragEnd={handleMarkerDragEnd}
                        />
                        {mapMarkers.map((marker, index) => (
                            <Marker
                                key={index}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                onClick={() => handleSavedMarkerClick(marker.id)}
                            />
                        ))}
                        {selectedPlace && selectedPlace.lat && selectedPlace.lng && (
                            <InfoWindow
                                position={{
                                    lat: selectedPlace.lat,
                                    lng: selectedPlace.lng,
                                }}
                                onCloseClick={() => setSelectedPlace(null)}
                            >
                                <div>
                                    <div className="open-mat-status">
                                        <div
                                            className={`indicator ${
                                                selectedPlace.openMat ? 'open' : 'closed'
                                            }`}
                                        ></div>
                                        <span>{selectedPlace.openMat ? 'Open Mat' : 'Open Mat'}</span>
                                    </div>
                                    <h2>
                                        <a
                                            href={selectedPlace.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {selectedPlace.name}
                                        </a>
                                    </h2>
                                    <p>{selectedPlace.vicinity || selectedPlace.address}</p>
                                    <p>
                                        {currentLocation && convertMeters(
                                            window.google.maps.geometry.spherical.computeDistanceBetween(
                                                new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng),
                                                new window.google.maps.LatLng(selectedPlace.lat, selectedPlace.lng)
                                            ),
                                            distanceUnit
                                        )} {distanceUnit} away
                                    </p>
                                    {selectedPlace.rating && <p>Rating: {selectedPlace.rating}</p>}
                                    {selectedPlace.user_ratings_total && (
                                        <p>Total Ratings: {selectedPlace.user_ratings_total}</p>
                                    )}
                                    {selectedPlace.formatted_phone_number && (
                                        <p>Phone: {selectedPlace.formatted_phone_number}</p>
                                    )}
                                    <button onClick={() => handleViewGymInfo(selectedPlace)}>
                                        View Gym Info
                                    </button>
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>
                {/* Show EditProfileModal if the profile is incomplete */}
                {isProfileIncomplete && profile && (
                    <EditProfileModal
                        profile={profile}
                        onSave={() => {
                            setIsProfileIncomplete(false);
                            navigate('/');
                        }}
                        onClose={() => {}}
                        canDismiss={false} // ensure the modal can't be closed until the profile is complete
                    />
                )}
            </div>
        </div>
    );
};

export default MapContainer;
