import React from 'react';
import '../../styles/common/Modal.css'; // Updated path for Modal.css

const Modal = ({ title, message, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{title}</h2>
                <p>{message}</p>
                <button onClick={onClose} className="modal-close-button">Close</button>
            </div>
        </div>
    );
};

export default Modal;
