import React from 'react';
import { Link } from 'react-router-dom';

const ReplyFeedItem = ({ data }) => {
    return (
        <div className="feed-item reply-feed-item">
            <div className="feed-item-header">
                <h3>
                    <Link to={`/profile/${data.replierId}`}>
                        {data.replierName}
                    </Link>{' '}
                    replied to your comment!
                </h3>
            </div>
            <p>Reply: {data.replyText}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
        </div>
    );
};

export default ReplyFeedItem;
