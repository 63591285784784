import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import '../../styles/ContactForm.css';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitStatus(null);
    
        try {
            await addDoc(collection(firestore, 'contactMessages'), {
                name,
                email,
                message,
                createdAt: new Date(),
                status: 'unread'
            });
            setSubmitStatus({ type: 'success', message: 'Message sent successfully!' });
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setSubmitStatus({ type: 'error', message: 'Failed to send message. Please try again.' });
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSubmitting) {
        return (
            <div className="contact-form-card">
                <h2>Submitting...</h2>
                <p>Please wait while we process your message.</p>
            </div>
        );
    }

    if (submitStatus) {
        return (
            <div className="contact-form-card">
                <h2>{submitStatus.type === 'success' ? 'Success!' : 'Error'}</h2>
                <p>{submitStatus.message}</p>
            </div>
        );
    }

    return (
        <div className="contact-form-card">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input 
                    type="text" 
                    id="name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                />

                <label htmlFor="email">Email:</label>
                <input 
                    type="email" 
                    id="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />

                <label htmlFor="message">Message:</label>
                <textarea 
                    id="message" 
                    value={message} 
                    onChange={(e) => setMessage(e.target.value)} 
                    required
                ></textarea>

                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default ContactForm;
