import React, { useState } from 'react';
import '../../styles/common/PostForm.css';
import ProgressBar from '../common/ProgressBar';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const PostForm = ({ handleSubmit, onUrlChange }) => {
    const [postType, setPostType] = useState('comment');
    const [commentText, setCommentText] = useState('');
    const [milestoneText, setMilestoneText] = useState('');
    const [milestoneDescription, setMilestoneDescription] = useState('');
    const [milestoneProgress, setMilestoneProgress] = useState(0);
    const [milestoneTargetDate, setMilestoneTargetDate] = useState('');
    const [achievementName, setAchievementName] = useState('');
    const [achievementType, setAchievementType] = useState('rank');
    const [trainingType, setTrainingType] = useState('BJJ');
    const [trainingDate, setTrainingDate] = useState('');
    const [trainingLength, setTrainingLength] = useState('');
    const [trainingAccomplishments, setTrainingAccomplishments] = useState('');
    const [trainingSetbacks, setTrainingSetbacks] = useState('');
    const [futureGoals, setFutureGoals] = useState('');
    const [techniquesPracticed, setTechniquesPracticed] = useState('');
    const [successfulSubmissions, setSuccessfulSubmissions] = useState('');
    const [failedSubmissions, setFailedSubmissions] = useState('');
    const [throwsPracticed, setThrowsPracticed] = useState('');
    const [takedownsPracticed, setTakedownsPracticed] = useState('');
    const [pinsPracticed, setPinsPracticed] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [openEmbedDialog, setOpenEmbedDialog] = useState(false);
    const [embedUrl, setEmbedUrl] = useState('');
    const [embeddedDomain, setEmbeddedDomain] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (commentText.trim()) {
            handleSubmit(commentText.trim(), embedUrl);
            setCommentText('');
            setEmbedUrl('');
            setEmbeddedDomain('');
        } else {
            console.error("Comment text is empty or invalid.");
        }
    };

    const handleMediaUrlChange = (e) => {
        setMediaUrl(e.target.value);
        onUrlChange(e.target.value);
    };

    const handleEmbedClick = () => setOpenEmbedDialog(true);

    const handleEmbedSubmit = () => {
        if (onUrlChange) {
            onUrlChange(embedUrl);
        }
        const domain = new URL(embedUrl).hostname.replace('www.', '');
        setEmbeddedDomain(domain);
        setOpenEmbedDialog(false);
    };

    const handleCommentSubmit = () => {
        handleSubmit(commentText);
        setCommentText('');
    };

    const handleUrlChange = (url) => {
        let embedCode = '';
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            const videoId = url.includes('youtube.com') ? url.split('v=')[1] : url.split('/').pop();
            embedCode = `https://www.youtube.com/embed/${videoId}`;
        } else if (url.includes('vimeo.com')) {
            const videoId = url.split('/').pop();
            embedCode = `https://player.vimeo.com/video/${videoId}`;
        } else if (url.includes('imgur.com')) {
            if (url.includes('/a/') || url.includes('/gallery/')) {
                const albumId = url.split('/').pop();
                embedCode = `https://imgur.com/a/${albumId}/embed`;
            } else {
                const imageId = url.split('/').pop().split('.')[0];
                embedCode = `https://i.imgur.com/${imageId}.jpg`;
            }
        }
        onUrlChange(embedCode);
    };

    const getContentBasedOnType = () => {
        switch (postType) {
            case 'comment':
                return commentText.trim();
            case 'milestone':
                return { text: milestoneText, description: milestoneDescription, progress: milestoneProgress, targetDate: milestoneTargetDate };
            case 'achievement':
                return { name: achievementName, type: achievementType };
            case 'trainingLog':
                return {
                    type: trainingType,
                    date: trainingDate,
                    length: trainingLength,
                    accomplishments: trainingAccomplishments,
                    setbacks: trainingSetbacks,
                    futureGoals: futureGoals,
                    techniquesPracticed: techniquesPracticed,
                    successfulSubmissions: successfulSubmissions,
                    failedSubmissions: failedSubmissions,
                    throwsPracticed: throwsPracticed,
                    takedownsPracticed: takedownsPracticed,
                    pinsPracticed: pinsPracticed
                };
            default:
                return '';
        }
    };

    const resetForm = () => {
        setCommentText('');
        setMilestoneText('');
        setMilestoneDescription('');
        setMilestoneProgress(0);
        setMilestoneTargetDate('');
        setAchievementName('');
        setAchievementType('rank');
        setTrainingType('BJJ');
        setTrainingDate('');
        setTrainingLength('');
        setTrainingAccomplishments('');
        setTrainingSetbacks('');
        setFutureGoals('');
        setTechniquesPracticed('');
        setSuccessfulSubmissions('');
        setFailedSubmissions('');
        setThrowsPracticed('');
        setTakedownsPracticed('');
        setPinsPracticed('');
        setMediaUrl('');
    };

    const renderTrainingFields = () => {
        // Unchanged from your original code
    };

    const renderForm = () => {
        switch (postType) {
            case 'milestone':
                return (
                    <div className="postform-milestone-container">
                        <input
                            type="text"
                            value={milestoneText}
                            onChange={(e) => setMilestoneText(e.target.value)}
                            placeholder="Milestone Title"
                            className="postform-input postform-milestone-input"
                        />
                        <textarea
                            value={milestoneDescription}
                            onChange={(e) => setMilestoneDescription(e.target.value)}
                            placeholder="Milestone Description"
                            className="postform-textarea postform-milestone-description"
                        />
                        <div className="postform-milestone-progress">
                            <label>Progress: {milestoneProgress}%</label>
                            <ProgressBar
                                progress={milestoneProgress}
                                setProgress={setMilestoneProgress}
                            />
                        </div>
                        <div className="postform-milestone-target-date">
                            <label>Target Completion Date:</label>
                            <input
                                type="date"
                                value={milestoneTargetDate}
                                onChange={(e) => setMilestoneTargetDate(e.target.value)}
                                className="postform-input postform-date-input"
                            />
                        </div>
                    </div>
                );
            case 'achievement':
                return (
                    <>
                        <input
                            type="text"
                            value={achievementName}
                            onChange={(e) => setAchievementName(e.target.value)}
                            placeholder="Achievement Name"
                            className="postform-input postform-achievement-input"
                        />
                        <select
                            value={achievementType}
                            onChange={(e) => setAchievementType(e.target.value)}
                            className="postform-select postform-achievement-type-select"
                        >
                            <option value="rank">Rank Promotion</option>
                            <option value="stripe">Stripe Promotion</option>
                            <option value="competition">Competition Win</option>
                        </select>
                    </>
                );
            case 'trainingLog':
                return (
                    <>
                        <select
                            value={trainingType}
                            onChange={(e) => setTrainingType(e.target.value)}
                            className="postform-select postform-training-type-select"
                        >
                            <option value="BJJ">BJJ</option>
                            <option value="Judo">Judo</option>
                            <option value="Wrestling">Wrestling</option>
                            <option value="Cardio">Cardio</option>
                            <option value="Weight Training">Weight Training</option>
                        </select>
                        <input
                            type="date"
                            value={trainingDate}
                            onChange={(e) => setTrainingDate(e.target.value)}
                            className="postform-input postform-training-date-input"
                        />
                        {renderTrainingFields()}
                        <textarea
                            value={futureGoals}
                            onChange={(e) => setFutureGoals(e.target.value)}
                            placeholder="Future goals"
                            className="postform-textarea postform-future-goals-textarea"
                        />
                    </>
                );
            case 'comment':
            default:
                return (
                    <div className="postform-comment-input-container">
                        <div className="textarea-wrapper">
                            <textarea
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                                placeholder="Post Something..."
                                className="postform-textarea postform-comment-textarea"
                            />
                            <Button
                                startIcon={<InsertLinkIcon />}
                                onClick={handleEmbedClick}
                                className="embed-button"
                                aria-label="Embed media"
                                tabIndex={0}
                                component="span"
                            >
                                {embeddedDomain || 'Embed'}
                            </Button>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="postform-container">
            <div className="postform-tabs">
                <div
                    className={`postform-tab ${postType === 'comment' ? 'active' : ''}`}
                    onClick={() => setPostType('comment')}
                >
                    <div className="postform-inner-text">Comment</div>
                </div>
                <div
                    className={`postform-tab ${postType === 'milestone' ? 'active' : ''}`}
                    onClick={() => setPostType('milestone')}
                >
                    <div className="postform-inner-text">Milestone</div>
                </div>
                <div
                    className={`postform-tab ${postType === 'achievement' ? 'active' : ''}`}
                    onClick={() => setPostType('achievement')}
                >
                    <div className="postform-inner-text">Achievement</div>
                </div>
                <div
                    className={`postform-tab ${postType === 'trainingLog' ? 'active' : ''}`}
                    onClick={() => setPostType('trainingLog')}
                >
                    <div className="postform-inner-text">Training Log</div>
                </div>
            </div>

            <form onSubmit={handleFormSubmit}>
                <div className="postform-content">
                    {renderForm()}
                    <button type="submit" className="postform-submit-button">Submit</button>

                </div>
                <div className="postform-actions">
                </div>
            </form>

            <Dialog open={openEmbedDialog} onClose={() => setOpenEmbedDialog(false)}>
                <DialogTitle>Embed Media</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="YouTube, Vimeo, or Imgur URL"
                        type="url"
                        fullWidth
                        variant="standard"
                        value={embedUrl}
                        onChange={(e) => setEmbedUrl(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmbedDialog(false)}>Cancel</Button>
                    <Button onClick={handleEmbedSubmit}>Embed</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PostForm;
