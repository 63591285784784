import React from 'react';

const CustomLegend = ({ payload, onClick, visibleMetrics }) => (
    <ul className="custom-legend">
        {payload.map((entry, index) => (
            <li
                key={`item-${index}`}
                onClick={() => onClick(entry.value)}
                style={{
                    color: visibleMetrics[entry.value] ? entry.color : '#aaa',
                    cursor: 'pointer',
                }}
            >
                <span
                    className="legend-icon"
                    style={{
                        backgroundColor: visibleMetrics[entry.value] ? entry.color : '#aaa',
                    }}
                ></span>
                {entry.value}
            </li>
        ))}
    </ul>
);

export default CustomLegend;
