import React from 'react';

const ProgressBar = ({ completionPercentage }) => (
    <div className="progress-bar">
        <div
            className="progress-bar-fill"
            style={{ width: `${completionPercentage}%` }}
        >
            {completionPercentage}%
        </div>
    </div>
);

export default ProgressBar;
