// src/components/common/Logo.js
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../services/firebase';
import '../../styles/common/Logo.css';

const Logo = () => {
    const [user] = useAuthState(auth);
    const [logoSrc, setLogoSrc] = useState(`${window.location.origin}/assets/OpenMatApp-default.png`); // Default logo

    useEffect(() => {
        const fetchBeltRank = async () => {
            if (user) {
                try {
                    const userDocRef = doc(firestore, 'users', user.uid);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists()) {
                        const fetchedBeltRank = userDoc.data().beltRank || 'default';

                        // Normalize the belt rank to lowercase
                        const normalizedRank = fetchedBeltRank.toLowerCase();

                        // Construct the image path directly
                        const logoPath = `${window.location.origin}/assets/OpenMatApp-${normalizedRank}.png`;

                        // Update the logoSrc state with the correct image path
                        setLogoSrc(logoPath);
                    }
                } catch (error) {
                    console.error('Error fetching belt rank:', error);
                    setLogoSrc(`${window.location.origin}/assets/OpenMatApp-default.png`); // Fallback logo on error
                }
            }
        };

        fetchBeltRank();
    }, [user]);

    return (
        <img src={logoSrc} alt="Open Mat App Logo" className="floating-logo" />
    );
};

export default Logo;
