import React from 'react';

const MediaPostFeedItem = ({ data }) => {
    return (
        <div className="feed-item media-post-feed-item">
            <div className="feed-item-header">
                <h3>{data.displayName} shared a {data.mediaType}:</h3>
            </div>
            <p>{data.caption}</p>
            {data.mediaType === 'photo' && <img src={data.mediaUrl} alt="Shared content" />}
            {data.mediaType === 'video' && <video src={data.mediaUrl} controls />}
            {data.mediaType === 'link' && (
                <a href={data.mediaUrl} target="_blank" rel="noopener noreferrer">
                    {data.mediaUrl}
                </a>
            )}
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
            <div className="feed-item-footer">
                <button className="like-button">Like</button>
                <button className="comment-button">Comment</button>
            </div>
        </div>
    );
};

export default MediaPostFeedItem;
