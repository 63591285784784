import React from 'react';

const ReportedContent = ({ reports }) => {
    return (
        <div className="reported-content">
            <h3>Reported Content</h3>
            {reports.length > 0 ? (
                <ul>
                    {reports.map((report, index) => (
                        <li key={index}>
                            <strong>{report.title}</strong> - Reported by {report.reportedBy}
                            <button className="resolve-button">Resolve</button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No reported content</p>
            )}
        </div>
    );
};

export default ReportedContent;
