import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore, storage } from '../../services/firebase';
import { doc, getDoc, updateDoc, setDoc, collection, getDocs, arrayUnion, arrayRemove } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FaStar, FaRegStar, FaThumbtack, FaFlag, FaUpload, FaEdit } from 'react-icons/fa';
import CommentsSection from './CommentsSection';
import RecurrenceModal from './RecurrenceModal';
import logActivity from '../../utils/logActivity';
import { saveEventWithRecurrence } from '../../services/recurrenceService';
import { formatUserLink, formatGymLink } from '../../utils/formatLink';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import '../../styles/EventLengthAlert.css';
import '../../styles/GymInfo.css';

SwiperCore.use([Navigation, Pagination]);

const GymInfo = () => {
    const {id: paramGymId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const gymId = paramGymId || location.state?.gymId || new URLSearchParams(location.search).get('gymId');
    const placeId = location.state?.placeId || gymId;
    const name = location.state?.name || new URLSearchParams(location.search).get('name');

    const [lat, setLat] = useState(location.state?.lat);
    const [lng, setLng] = useState(location.state?.lng);
    const address = location.state?.address;
    const [gym, setGym] = useState(null);
    const [isGymClaimed, setIsGymClaimed] = useState(false);
    const [gymOwnerName, setGymOwnerName] = useState('');
    const [gymLogoUrl, setGymLogoUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [promotionalText, setPromotionalText] = useState('');
    const [openMat, setOpenMat] = useState(false);
    const [locationSaved, setLocationSaved] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [favoritedBy, setFavoritedBy] = useState([]);
    const [favoritedByDetails, setFavoritedByDetails] = useState([]);
    const [events, setEvents] = useState([]);
    const [comments, setComments] = useState([]);
    const [user] = useAuthState(auth);
    const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
    const [newEventDetails, setNewEventDetails] = useState(null);
    const [showEventLengthAlert, setShowEventLengthAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
    const [claimReference, setClaimReference] = useState('');
    const [isModerator, setIsModerator] = useState(false);
    const shareUrl = window.location.href;

    const handleSaveRecurrence = (recurrence, occurrences) => {
        saveEventWithRecurrence(
            user,
            placeId,
            gym,
            newEventDetails,
            recurrence,
            occurrences,
            setEvents,
            checkAndSetOpenMatStatus,
            () => setShowRecurrenceModal(false),
            () => setNewEventDetails(null)
        );
    };

    const EventLengthAlert = ({message, onClose}) => {
        return (
            <div className="event-length-alert-overlay">
                <div className="event-length-alert-box">
                    <span style={{whiteSpace: 'pre-line', textAlign: 'center'}}>{message}</span>
                    <div style={{marginTop: '20px', textAlign: 'center'}}>
                        <button onClick={onClose} style={{padding: '10px 20px'}}>OK</button>
                    </div>
                </div>
            </div>
        );
    };

    const handleDateSelect = useCallback(
        (selectionInfo) => {
            const {startStr, endStr, start, end} = selectionInfo;
            const minDuration = 1 * 60 * 60 * 1000;
            if (end - start < minDuration) {
                setAlertMessage("Events must be at least 1 hour long.\nC'mon, who wants an open mat less than an hour long?");
                setShowEventLengthAlert(true);
                return;
            }

            const hasOverlap = events.some(event =>
                start < event.end && end > event.start
            );
            if (hasOverlap) {
                setAlertMessage('Events cannot overlap.');
                setShowEventLengthAlert(true);
                return;
            }

            const title = "Open Mat";
            if (user) {
                setNewEventDetails({title, start: new Date(startStr), end: new Date(endStr)});
                setShowRecurrenceModal(true);
            } else {
                console.error('User not authenticated');
            }
        },
        [events, user]
    );

    const renderEventContent = (eventInfo) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {eventInfo.timeText} {eventInfo.event.title}
                </div>
                <div style={{display: 'flex', gap: '10px', paddingRight: '10px', paddingTop: '5px'}}>
                    <FacebookShareButton url={window.location.href} quote={eventInfo.event.title}>
                        <FacebookIcon size={20} round={true}/>
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href} title={eventInfo.event.title}>
                        <TwitterIcon size={20} round={true}/>
                    </TwitterShareButton>
                    <WhatsappShareButton url={window.location.href} title={eventInfo.event.title}>
                        <WhatsappIcon size={20} round={true}/>
                    </WhatsappShareButton>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchGymData = async () => {
            try {
                const gymDocRef = doc(firestore, 'locations', gymId);
                const gymDoc = await getDoc(gymDocRef);
                if (gymDoc.exists()) {
                    const gymData = gymDoc.data();
                    setGym(gymData);
                    setFavoritedBy(gymData.favoritedBy || []);
                    setGym(gymData);
                    setOpenMat(gymData.openMat || false);
                    setLocationSaved(gymData.locationSaved || false);
                    setFavoritedBy(gymData.favoritedBy || []);
                    setGymLogoUrl(gymData.logoUrl || '');
                    setBannerUrl(gymData.bannerUrl || '');
                    setPromotionalText(gymData.promotionalText || '');

                    // Check if the gym is claimed by fetching the claim request
                    const claimRequestRef = doc(firestore, 'claimRequests', gymId);
                    const claimRequestDoc = await getDoc(claimRequestRef);
                    if (claimRequestDoc.exists()) {
                        const claimRequestData = claimRequestDoc.data();

                        if (claimRequestData.status === 'approved' && claimRequestData.type === 'gym') {
                            setIsGymClaimed(true);

                            // Fetch the owner's name
                            const ownerDocRef = doc(firestore, 'users', claimRequestData.userId);
                            const ownerDoc = await getDoc(ownerDocRef);
                            if (ownerDoc.exists()) {
                                setGymOwnerName(ownerDoc.data().displayName);
                            }

                            // Check if the current user is the gym owner
                            if (user && user.uid === claimRequestData.userId) {
                                setIsModerator(true);
                            }
                        }
                    } else {
                        setIsGymClaimed(false);
                        setIsModerator(false);
                    }
                } else {
                    console.error('Gym document does not exist.');
                }
            } catch (error) {
                console.error('Error fetching gym data:', error);
            }
        };

        const toggleFavorite = async () => {
            if (user && gymId) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const gymDocRef = doc(firestore, 'locations', gymId);

                try {
                    if (isFavorite) {
                        // Remove from favorites
                        await updateDoc(userDocRef, {
                            favorites: arrayRemove(gymId)
                        });
                        await updateDoc(gymDocRef, {
                            favoritedBy: arrayRemove(user.uid)
                        });

                        // Log the unfavorite activity
                        await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} removed ${formatGymLink(gymId, gym.name)} from favorites`, 'gym_unfavorite', {
                            userId: user.uid,
                            userName: user.displayName,
                            gymId: gymId,
                            gymName: gym.name,
                            userPhotoURL: user.photoURL
                        });
                    } else {
                        // Add to favorites
                        await updateDoc(userDocRef, {
                            favorites: arrayUnion(gymId)
                        });
                        await updateDoc(gymDocRef, {
                            favoritedBy: arrayUnion(user.uid)
                        });

                        // Log the favorite activity
                        await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} added ${formatGymLink(gymId, gym.name)} to favorites`, 'gym_favorite', {
                            userId: user.uid,
                            userName: user.displayName,
                            gymId: gymId,
                            gymName: gym.name,
                            userPhotoURL: user.photoURL
                        });
                    }

                    // Update the local state
                    setIsFavorite(!isFavorite);
                    setFavoritedBy((prev) =>
                        isFavorite ? prev.filter((uid) => uid !== user.uid) : [...prev, user.uid]
                    );
                } catch (error) {
                    console.error('Error updating favorites: ', error);
                }
            }
        };


        if (gymId) {
            fetchGymData();
        } else {
            console.error('No gymId found in location.state or URL parameters');
        }
    }, [gymId, user]);

    useEffect(() => {
        const fetchFavoritedByDetails = async () => {
            if (favoritedBy.length > 0) {
                const userDetails = await Promise.all(
                    favoritedBy.map(async (userId) => {
                        const userDoc = await getDoc(doc(firestore, 'users', userId));
                        return userDoc.exists() ? { uid: userDoc.id, ...userDoc.data() } : null;
                    })
                );

                setFavoritedByDetails(userDetails.filter(user => user !== null));

                // Check if the current user is in the favoritedBy list
                const currentUserUid = auth.currentUser?.uid;
                if (currentUserUid) {
                    const userHasFavorited = favoritedBy.includes(currentUserUid);
                    setIsFavorite(userHasFavorited);
                }
            }
        };

        fetchFavoritedByDetails();
    }, [favoritedBy]);


    useEffect(() => {
        const fetchEvents = async () => {
            if (gymId) {
                try {
                    const eventsCollection = collection(firestore, 'locations', gymId, 'events');
                    const eventsSnapshot = await getDocs(eventsCollection);

                    const eventsList = eventsSnapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            title: data.title,
                            start: data.start.toDate(),
                            end: data.end.toDate(),
                            addedBy: data.addedBy,
                            attendees: data.attendees || [],
                            recurrence: data.recurrence || 'none'
                        };
                    });

                    setEvents(eventsList);
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            }
        };
        fetchEvents();
    }, [gymId]);

    useEffect(() => {
        const fetchComments = async () => {
            if (gymId) {
                const commentsRef = collection(firestore, `locations/${gymId}/comments`);
                const snapshot = await getDocs(commentsRef);
                const commentsList = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
                setComments(commentsList);
            }
        };

        fetchComments();
    }, [gymId]);

    const saveLocation = async () => {
        try {
            // Determine the new value for locationSaved
            const newLocationSaved = !locationSaved;

            // Update the Firestore document
            const gymDocRef = doc(firestore, 'locations', placeId);

            // Prepare the data to update
            const updateData = {
                locationSaved: newLocationSaved,
            };

            // If the location is being pinned for the first time, update lat and lng
            if (newLocationSaved && (lat !== undefined && lng !== undefined)) {
                updateData.lat = lat;
                updateData.lng = lng;
            }

            // Update the Firestore document with the new values
            await updateDoc(gymDocRef, updateData);

            // Update the local state
            setLocationSaved(newLocationSaved);

            // Log the activity
            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} ${newLocationSaved ? 'pinned' : 'unpinned'} Gym ${newLocationSaved ? 'to' : 'from'} ${formatGymLink(placeId, gym.name)}`, 'gym_pin', {
                userId: user.uid,
                userName: user.displayName,
                gymId: placeId,
                gymName: gym.name,
                userPhotoURL: user.photoURL
            });

        } catch (error) {
            console.error('Error saving location:', error);
        }
    };

    const toggleFavorite = async () => {
        if (user && gymId) {
            const userDocRef = doc(firestore, 'users', user.uid);
            const gymDocRef = doc(firestore, 'locations', gymId);

            try {
                if (isFavorite) {
                    // Remove from favorites
                    await updateDoc(userDocRef, {
                        favorites: arrayRemove(gymId)
                    });
                    await updateDoc(gymDocRef, {
                        favoritedBy: arrayRemove(user.uid)
                    });

                    await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} removed ${formatGymLink(gymId, gym.name)} from favorites`, 'gym_unfavorite', {
                        userId: user.uid,
                        userName: user.displayName,
                        gymId: gymId,
                        gymName: gym.name,
                        userPhotoURL: user.photoURL
                    });
                } else {
                    // Add to favorites
                    await updateDoc(userDocRef, {
                        favorites: arrayUnion(gymId)
                    });
                    await updateDoc(gymDocRef, {
                        favoritedBy: arrayUnion(user.uid)
                    });

                    await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} added ${formatGymLink(gymId, gym.name)} to favorites`, 'gym_favorite', {
                        userId: user.uid,
                        userName: user.displayName,
                        gymId: gymId,
                        gymName: gym.name,
                        userPhotoURL: user.photoURL
                    });
                }

                // Update the local state
                setIsFavorite(!isFavorite);
                setFavoritedBy((prev) =>
                    isFavorite ? prev.filter((uid) => uid !== user.uid) : [...prev, user.uid]
                );
            } catch (error) {
                console.error('Error updating favorites: ', error);
            }
        }
    };

    const checkAndSetOpenMatStatus = useCallback(async () => {
        if (!gymId) {
            console.error('Invalid gymId. It should be a non-empty string.');
            return;
        }

        try {
            const now = new Date();

            // Fetch all events for the location
            const eventsCollection = collection(firestore, 'locations', gymId, 'events');
            const eventsSnapshot = await getDocs(eventsCollection);

            // Filter events to find any that are currently ongoing or scheduled in the future
            const relevantEvents = eventsSnapshot.docs.filter(doc => {
                const eventData = doc.data();
                const eventStart = eventData.start.toDate();
                const eventEnd = eventData.end.toDate();
                // Check if the event is ongoing or in the future
                return eventEnd >= now;
            });

            // Determine if there are any ongoing or future events
            const openMatExists = relevantEvents.length > 0;

            // Update the openMat status in Firestore
            await updateDoc(doc(firestore, 'locations', gymId), {openMat: openMatExists});
            setOpenMat(openMatExists);

        } catch (error) {
            console.error('Error updating openMat status:', error);
        }
    }, [gymId]);

    useEffect(() => {
        checkAndSetOpenMatStatus(); // Call this on component load to ensure openMat status is accurate
    }, [checkAndSetOpenMatStatus]);

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `gymLogos/${gymId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress if needed
            },
            (error) => {
                console.error('Upload error:', error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                await updateDoc(doc(firestore, 'locations', gymId), {logoUrl: downloadURL});
                setGymLogoUrl(downloadURL);
            }
        );
    };

    const handleBannerUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `gymBanners/${gymId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress if needed
            },
            (error) => {
                console.error('Upload error:', error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                await updateDoc(doc(firestore, 'locations', gymId), {bannerUrl: downloadURL});
                setBannerUrl(downloadURL);
            }
        );
    };

    const handlePromotionalTextChange = async (event) => {
        const text = event.target.value;
        setPromotionalText(text);
        await updateDoc(doc(firestore, 'locations', gymId), {promotionalText: text});
    };

    const handleClaimGym = async () => {
        if (user) {
            try {
                // Generate a unique reference number
                const referenceNumber = Math.random().toString(36).substring(2, 15);

                // Store the claim request in Firestore with the correct type and status
                const claimRequestRef = doc(firestore, 'claimRequests', gymId);
                await setDoc(claimRequestRef, {
                    gymId,
                    userId: user.uid,
                    status: 'pending',
                    type: 'gym',
                    referenceNumber: referenceNumber,
                    createdAt: new Date(),
                });

                // Set the reference number state
                setClaimReference(referenceNumber);

                // Open the claim modal
                setIsClaimModalOpen(true); // Make sure this line exists
            } catch (error) {
                console.error('Error submitting claim request:', error);
            }
        } else {
            alert('You need to be logged in to claim a gym.');
        }
    };

    const handleClaimSubmit = () => {
        // Open Instagram with a pre-filled message
        const instagramURL = `https://www.instagram.com/openmatapp/`;
        window.open(instagramURL, '_blank');

        // Close the modal
        setIsClaimModalOpen(false);
    };

    const bannerStyle = bannerUrl
        ? {
            backgroundImage: `url(${bannerUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.4,
        }
        : {};

    if (!gym) return <div>Loading...</div>;

    return (
        <div className="gym-info-container">
            {bannerUrl && (
                <div className="gym-banner">
                    <img src={bannerUrl} alt="Gym Banner" />
                </div>
            )}
            <div className="top-gym-info-card">
                <div className="top-half">
                    <div className="left-section">
                        <div className="open-mat-status-container">
                            <div className="open-mat-status">
                                <div className={`indicator ${openMat ? 'open' : 'closed'}`}></div>
                                <span>{openMat ? 'Open Mat' : 'No Open Mat'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="center-section">
                        {isGymClaimed && isModerator && (
                            <div className="gym-logo-placeholder">
                                {gymLogoUrl ? (
                                    <img src={gymLogoUrl} alt="Gym Logo" className="gym-logo"/>
                                ) : (
                                    <label htmlFor="logo-upload" className="upload-label logo-upload-label">
                                        <FaUpload/>
                                        <span>Upload Logo</span>
                                        <input
                                            id="logo-upload"
                                            type="file"
                                            onChange={handleLogoUpload}
                                            style={{display: 'none'}}
                                        />
                                    </label>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="right-section">
                        <div className="claimed-gym-container">
                            {isGymClaimed ? (
                                <p className="gym-claimed">Owned by {gymOwnerName}</p>
                            ) : (
                                <button className="claim-gym-btn" onClick={handleClaimGym}>
                                    <FaFlag/> Claim Gym
                                </button>
                            )}
                        </div>
                        <div className="pin-gym-container">
                            <button className="pin-gym-btn" onClick={saveLocation}>
                                <FaThumbtack/> {locationSaved ? 'Unpin Gym' : 'Pin Gym'}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="bottom-half">
                    <h2 className="gym-name">{gym.name}</h2>
                    <div className="favorite-gym-container">
                        <span className="favorite-gym-btn" onClick={toggleFavorite}>
                            {isFavorite ? <FaStar className="star-icon"/> : <FaRegStar className="star-icon"/>}
                            <span className="count">{favoritedBy.length}</span>
                        </span>
                    </div>
                </div>
            </div>


            <div className="gym-info-card">
                <h3>Open Mat Schedule</h3>
                <div className="calendar-container">
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        height={825}
                        initialView="timeGridDay"
                        selectable={true}
                        events={events}
                        eventContent={renderEventContent}
                        slotMinTime="07:00:00"
                        slotMaxTime="22:00:00"
                        allDaySlot={false}
                        selectMirror={true}
                        select={handleDateSelect}
                        eventClick={({event}) => {
                            navigate(`/gym/${gymId}/event/${event.id}`, {state: {gymId}});
                        }}
                    />
                </div>
            </div>

            <CommentsSection
                uniqueId={gymId}
                collectionType={`locations/${gymId}/comments`}
                comments={comments}
                setComments={setComments}
                currentUser={user}
                pageType="gym"
                commentType="location" 
                itemId={gymId}
            />

            <div className="gym-info-card gym-opening-hours">
                <h3>Opening Hours</h3>
                {gym.opening_hours && gym.opening_hours.length > 0 ? (
                    <ul>
                        {gym.opening_hours.map((hour, index) => (
                            <li key={index}>{hour}</li>
                        ))}
                    </ul>
                ) : (
                    <p>No opening hours available.</p>
                )}
            </div>

            <div className="gym-info-card gym-contact-info">
                <h3>Contact Information</h3>
                <p>Phone: {gym.phone || 'N/A'}</p>
                <p>
                    Website:{' '}
                    {gym.website ? (
                        <a href={gym.website} target="_blank" rel="noopener noreferrer">
                            {gym.website}
                        </a>
                    ) : (
                        'N/A'
                    )}
                </p>
            </div>

            <div className="gym-info-card gym-favorited-by">
                <h3>Favorited By</h3>
                {favoritedByDetails.length > 0 ? (
                    <ul className="user-following-list">
                        {favoritedByDetails.map((user) => (
                            <li key={user.uid} className="user-following-item">
                                <Link to={`/profile/${user.uid}`}>
                                    <div className="user-following-content">
                                        <img src={user.photoURL || 'placeholder.png'} alt="User"
                                             className="user-following-pic"/>
                                        <span className="user-following-name">{user.displayName}</span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No users have favorited this gym yet.</p>
                )}
            </div>

            <div className="gym-info-card">
                <div className="gym-photos">
                    <h3>Photos</h3>
                    <Swiper navigation pagination={{clickable: true}} spaceBetween={10} slidesPerView={1}
                            className="photo-wrapper">
                        {gym.photos &&
                            gym.photos.map((photo, index) => (
                                <SwiperSlide key={index} className="photo-slide">
                                    <img src={photo} alt={`Gym moments ${index + 1}`}/>
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </div>
                <div className="gym-reviews">
                    <h3>Reviews</h3>
                    <ul>
                        {gym.reviews && gym.reviews.length > 0 ? (
                            gym.reviews.map((review, index) => (
                                <li key={index} className="review-card">
                                    <div className="review-author">{review.author_name}</div>
                                    <div className="review-text">{review.text}</div>
                                    <div className="review-rating">{'⭐'.repeat(review.rating)}</div>
                                </li>
                            ))
                        ) : (
                            <li>No reviews available.</li>
                        )}
                    </ul>
                </div>
            </div>

            {showRecurrenceModal && (
                <RecurrenceModal onClose={() => setShowRecurrenceModal(false)} onSave={handleSaveRecurrence}/>
            )}

            {showEventLengthAlert && (
                <EventLengthAlert message={alertMessage} onClose={() => setShowEventLengthAlert(false)}/>
            )}

            {isClaimModalOpen && (
                <div className="claim-gym-modal-overlay">
                    <div className="claim-gym-modal">
                        <h3>Claim This Gym</h3>
                        <p>
                            Your reference number is: <strong>{claimReference}</strong>
                        </p>
                        <p>
                            We have copied this number to your clipboard. Please message us on Instagram with this
                            reference number to verify your ownership.
                        </p>
                        <button onClick={handleClaimSubmit} className="claim-gym-submit-button">
                            Open Instagram
                        </button>
                        <button onClick={() => setIsClaimModalOpen(false)} className="claim-gym-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );

};

export default GymInfo;
