import { doc, addDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, collection, query, orderBy, getDocs, serverTimestamp } from 'firebase/firestore';
import { auth, firestore } from './firebase';

// Helper function to get the root path for post types
const getPostTypeRoot = (collectionType) => {
    return collectionType;
};

export const addComment = async (userId, collectionType, commentData) => {
    try {
        const commentToAdd = {
            ...commentData,
            createdAt: serverTimestamp(),
            likes: [],
            replies: [],
            embedUrl: commentData.embedUrl || "",
            profilePicUrl: commentData.profilePicUrl || 'default-profile.png',
        };

        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const commentRef = await addDoc(collection(firestore, postTypeRoot), commentToAdd);
        return { id: commentRef.id, ...commentToAdd };

    } catch (error) {
        console.error('Error adding comment:', error);
        throw error;
    }
};

export const addReply = async (userId, collectionType, commentId, replyData) => {
    if (!userId || !collectionType || !commentId || !replyData) {
        throw new Error('Missing required parameters for addReply');
    }

    const postTypeRoot = getPostTypeRoot(userId, collectionType);
    const reply = {
        ...replyData,
        createdAt: serverTimestamp(),
        likes: [],
    };

    try {
        const replyRef = await addDoc(collection(firestore, `${postTypeRoot}/${commentId}/replies`), reply);
        return { id: replyRef.id, ...reply };
    } catch (error) {
        console.error('Error adding reply:', error);
        throw error;
    }
};

const getCommentPath = (commentType, itemId) => {
    switch (commentType) {
        case 'user':
            return `/users/${itemId}/comments`;
        case 'location':
            return `/locations/${itemId}/comments`;
        case 'event':
            const [locationId, eventId] = itemId.split('|');
            return `/locations/${locationId}/events/${eventId}/comments`;
        default:
            throw new Error('Invalid comment type');
    }
};

export const addMilestone = async (userId, collectionType, milestoneData, user) => {
    if (!userId || !milestoneData || !user) {
        console.error('Missing required parameters for addMilestone');
        return null;
    }

    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType); // Generate postTypeRoot using the function
        const milestoneId = milestoneData.id || `milestone_${Date.now()}`;

        // Using the corrected path structure
        const milestoneRef = doc(firestore, `${postTypeRoot}/${milestoneId}`);

        await addDoc(milestoneRef, {
            ...milestoneData,
            uid: user.uid,
            userName: user.displayName || 'Anonymous',
            profilePicUrl: user.photoURL || '',
            createdAt: serverTimestamp(),
            likes: [],
            replies: []
        });

        return { id: milestoneId, ...milestoneData };
    } catch (error) {
        console.error(`Error adding milestone:`, error);
        return null;
    }
};

export const getMilestoneById = async (userId, collectionType, milestoneId) => {
    const milestoneRef = doc(firestore, `${getPostTypeRoot(userId, collectionType)}/${milestoneId}`);
    const milestoneDoc = await getDoc(milestoneRef);
    if (milestoneDoc.exists()) {
        return { id: milestoneDoc.id, ...milestoneDoc.data() };
    } else {
        return null;
    }
};

export const updateMilestoneProgress = async (userId, collectionType, milestoneId, milestoneData) => {
    const milestoneRef = doc(firestore, `${getPostTypeRoot(userId, collectionType)}/${milestoneId}`);
    await updateDoc(milestoneRef, milestoneData);
};

export const deleteMilestone = async (userId, collectionType, milestoneId) => {
    const milestoneRef = doc(firestore, `${getPostTypeRoot(userId, collectionType)}/${milestoneId}`);
    await deleteDoc(milestoneRef);
};

export const addAchievement = async (userId, itemId, achievementName, user, collectionType) => {
    if (!userId || !itemId || !achievementName || !user || !collectionType) {
        console.error('Missing required parameters for addAchievement');
        return null;
    }

    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const achievementId = `achievement_${Date.now()}`;
        const achievementData = {
            achievementName,
            uid: user.uid,
            userName: user.displayName || 'Anonymous',
            profilePicUrl: user.photoURL || '',
            createdAt: serverTimestamp(),
            likes: [],
            replies: []
        };

        const achievementRef = doc(firestore, `${postTypeRoot}/${achievementId}`);
        await addDoc(achievementRef, achievementData);

        return achievementData;
    } catch (error) {
        console.error(`Error adding achievement:`, error);
        return null;
    }
};

export const addMediaPost = async (userId, itemId, mediaData, user, collectionType) => {
    if (!userId || !itemId || !mediaData || !user || !collectionType) {
        console.error('Missing required parameters for addMediaPost');
        return null;
    }

    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const mediaPostId = `mediaPost_${Date.now()}`;
        const mediaPostData = {
            ...mediaData,
            uid: user.uid,
            userName: user.displayName || 'Anonymous',
            profilePicUrl: user.photoURL || '',
            createdAt: serverTimestamp(),
            likes: [],
            replies: []
        };

        const mediaPostRef = doc(firestore, `${postTypeRoot}/${mediaPostId}`);
        await addDoc(mediaPostRef, mediaPostData);

        return mediaPostData;
    } catch (error) {
        console.error(`Error adding media post:`, error);
        return null;
    }
};

export const addTrainingLog = async (userId, itemId, trainingSummary, user, collectionType) => {
    if (!userId || !itemId || !trainingSummary || !user || !collectionType) {
        console.error('Missing required parameters for addTrainingLog');
        return null;
    }

    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const trainingLogId = `trainingLog_${Date.now()}`;
        const trainingLogData = {
            trainingSummary,
            uid: user.uid,
            userName: user.displayName || 'Anonymous',
            profilePicUrl: user.photoURL || '',
            createdAt: serverTimestamp(),
            likes: [],
            replies: []
        };

        const trainingLogRef = doc(firestore, `${postTypeRoot}/${trainingLogId}`);
        await addDoc(trainingLogRef, trainingLogData);

        return trainingLogData;
    } catch (error) {
        console.error(`Error adding training log:`, error);
        return null;
    }
};

export const deleteComment = async (userId, collectionType, commentId) => {
    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const commentRef = doc(firestore, `${postTypeRoot}/${commentId}`);
        await deleteDoc(commentRef);
    } catch (error) {
        console.error('Error deleting comment:', error);
    }
};

export const deleteReply = async (userId, collectionType, commentId, replyId) => {
    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const replyRef = doc(firestore, `${postTypeRoot}/${commentId}/replies/${replyId}`);
        await deleteDoc(replyRef);
    } catch (error) {
        console.error('Error deleting reply:', error);
        throw error;
    }
};

export const toggleLike = async (userId, collectionType, uniqueId, commentId, user, isLiked, isReply = false, replyId = null) => {
    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        let ref;
        if (isReply && replyId) {
            ref = doc(firestore, `${postTypeRoot}/${commentId}/replies/${replyId}`);
        } else {
            ref = doc(firestore, `${postTypeRoot}/${commentId}`);
        }

        await updateDoc(ref, {
            likes: isLiked ? arrayRemove(user.uid) : arrayUnion(user.uid)
        });
    } catch (error) {
        console.error("Error toggling like:", error);
    }
};

export const fetchItemsWithReplies = async (userId, collectionType) => {
    if (!userId || !collectionType) {
        console.error('Missing required parameters for fetchItemsWithReplies');
        return [];
    }

    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const commentsRef = collection(firestore, postTypeRoot);
        const q = query(commentsRef, orderBy('createdAt', 'desc'));

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return [];
        }

        const comments = await Promise.all(querySnapshot.docs.map(async (doc) => {
            const commentData = { id: doc.id, ...doc.data() };
            const repliesRef = collection(doc.ref, 'replies');
            const repliesSnapshot = await getDocs(query(repliesRef, orderBy('createdAt', 'asc')));
            commentData.replies = repliesSnapshot.docs.map(replyDoc => ({ id: replyDoc.id, ...replyDoc.data() }));
            console.log('comments:', comments);
            console.log('comments[0].replies:', comments[0].replies); 
            return commentData;
        }));

        return comments;
    } catch (error) {
        console.error("Error fetching comments:", error);
        return [];
    }
};

export { getPostTypeRoot };