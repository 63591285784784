import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../services/firebase';
import '../../../styles/FeedItem.css';

const FollowFeedItem = ({ data }) => {
    const [followedUserName, setFollowedUserName] = useState('another user');
    const [followedUserPhotoURL, setFollowedUserPhotoURL] = useState('');

    useEffect(() => {
        const fetchFollowedUserInfo = async () => {
            if (data.userId) {
                try {
                    const userDocRef = doc(firestore, 'users', data.userId);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setFollowedUserName(userData.displayName || 'another user');
                        setFollowedUserPhotoURL(userData.photoURL || '');
                    }
                } catch (error) {
                    console.error('Error fetching followed user data:', error);
                }
            }
        };

        fetchFollowedUserInfo();
    }, [data.userId]);

    return (
        <div className="feed-item">
            <h3>
                <Link to={`/profile/${data.details?.followerId}`}>
                    {data.details?.userPhotoURL && (
                        <img
                            src={data.details.userPhotoURL}
                            alt={`${data.details?.followerName}'s profile`}
                            className="profile-pic"
                        />
                    )}
                    {data.details?.followerName || 'A user'}
                </Link>
                {' '}followed{' '}
                <Link to={`/profile/${data.userId}`}>
                    {followedUserPhotoURL && (
                        <img
                            src={followedUserPhotoURL}
                            alt={`${followedUserName}'s profile`}
                            className="profile-pic"
                        />
                    )}
                    {followedUserName}
                </Link>
            </h3>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
            <button className="follow-button">Follow Back</button>
        </div>
    );
};

export default FollowFeedItem;
