import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { allCountries } from 'country-region-data';
import '../../styles/EditProfileModal.css';
import { doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase';

Modal.setAppElement('#root');

const EditProfileModal = ({ profile, onSave, forceCompleteProfile = false }) => {
    const [selectedCountry, setSelectedCountry] = useState(profile.country || '');
    const [selectedRegion, setSelectedRegion] = useState(profile.region || '');
    const [selectedCity, setSelectedCity] = useState(profile.city || '');
    const [beltRank, setBeltRank] = useState(profile.beltRank || '');
    const [bio, setBio] = useState(profile.bio || '');
    const [requestCalendar, setRequestCalendar] = useState(profile.requestCalendar || false);

    useEffect(() => {
        if (profile.country) setSelectedCountry(profile.country);
        if (profile.region) setSelectedRegion(profile.region);
        if (profile.city) setSelectedCity(profile.city);
    }, [profile]);

    const handleSave = async () => {
        if (selectedCountry && selectedRegion && selectedCity && beltRank && bio) {
            const userId = profile.uid;
            if (!userId) {
                console.error('User ID is undefined');
                return;
            }

            try {
                const docRef = doc(firestore, 'users', userId);
                const calendarRequestRef = doc(firestore, 'claimRequests', userId);

                const calendarRequestSnapshot = await getDoc(calendarRequestRef);
                const calendarRequestExists = calendarRequestSnapshot.exists();
                const calendarRequestStatus = calendarRequestExists
                    ? calendarRequestSnapshot.data().status
                    : null;

                await updateDoc(docRef, {
                    hometown: `${selectedCity}, ${selectedRegion}, ${selectedCountry}`,
                    country: selectedCountry,
                    region: selectedRegion,
                    city: selectedCity,
                    beltRank,
                    bio,
                    requestCalendar: requestCalendar && !calendarRequestExists,
                });

                if (requestCalendar && (!calendarRequestExists || calendarRequestStatus !== 'approved')) {
                    await setDoc(calendarRequestRef, {
                        userId: userId,
                        type: 'calendar_request',
                        status: 'pending',
                        createdAt: new Date(),
                    });
                } else if (!requestCalendar && calendarRequestExists) {
                    await updateDoc(calendarRequestRef, { status: 'removed' });
                }

                onSave({
                    ...profile,
                    hometown: `${selectedCity}, ${selectedRegion}, ${selectedCountry}`,
                    country: selectedCountry,
                    region: selectedRegion,
                    city: selectedCity,
                    beltRank,
                    bio,
                    requestCalendar,
                });
            } catch (error) {
                console.error('Error saving profile or requesting/removing calendar:', error);
            }
        } else {
            alert('All fields are required.');
        }
    };

    const handleCountryChange = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        setSelectedRegion('');
        setSelectedCity('');
    };

    const handleRegionChange = (event) => {
        const region = event.target.value;
        setSelectedRegion(region);
        setSelectedCity('');
    };

    const handleCityChange = (event) => setSelectedCity(event.target.value);

    const selectedCountryData = allCountries.find(country => country[1] === selectedCountry);
    const regions = selectedCountryData && Array.isArray(selectedCountryData[2])
        ? selectedCountryData[2].map(region => Array.isArray(region) ? region[0] : region)
        : [];

    return (
        <Modal
            isOpen={true}
            contentLabel="Edit Profile"
            className="edit-profile-modal"
            overlayClassName="edit-profile-modal-overlay"
            style={{ content: { top: '100px' } }}
        >
            <h2>Edit Profile</h2>
            <div className="form-group">
                <label>Hometown</label>
                <select value={selectedCountry} onChange={handleCountryChange}>
                    <option value="">Select Country</option>
                    {allCountries.map((country) => (
                        <option key={country[1]} value={country[1]}>
                            {country[0]}
                        </option>
                    ))}
                </select>
                <select value={selectedRegion} onChange={handleRegionChange} disabled={!selectedCountry}>
                    <option value="">Select State/Province</option>
                    {regions.map((region, index) => (
                        <option key={`${selectedCountry}-${index}`} value={region}>
                            {region}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    value={selectedCity}
                    onChange={handleCityChange}
                    placeholder="Enter City"
                    disabled={!selectedRegion}
                />
            </div>
            <div className="form-group">
                <label>Belt Rank</label>
                <select value={beltRank} onChange={(e) => setBeltRank(e.target.value)}>
                    <option value="">Select Belt Rank</option>
                    <option value="None">None</option>
                    <option value="White">White</option>
                    <option value="Blue">Blue</option>
                    <option value="Purple">Purple</option>
                    <option value="Brown">Brown</option>
                    <option value="Black">Black</option>
                    <option value="Coral">Coral</option>
                    <option value="Red">Red</option>
                </select>
            </div>
            <div className="form-group">
                <label>Bio</label>
                <textarea
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    placeholder="Tell us about yourself"
                />
            </div>
            <div className="calendar-request-group">
                <label>
                    <span>Request Events Calendar</span>
                    <input
                        type="checkbox"
                        checked={requestCalendar}
                        onChange={(e) => setRequestCalendar(e.target.checked)}
                    />
                </label>
            </div>

            <div className="form-actions">
                <button onClick={handleSave}>Save</button>
            </div>
        </Modal>
    );
};

export default EditProfileModal;
