// src/components/common/LoadingOverlay.js
import React from 'react';
import '../../styles/common/LoadingOverlay.css'; // Updated path to the CSS file

const LoadingOverlay = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-indicator">Working...</div>
        </div>
    );
};

export default LoadingOverlay;
