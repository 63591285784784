import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useComments } from '../../hooks/useComments';
import { Avatar, Card, CardHeader, CardContent, Typography, Box, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import PostForm from '../forms/PostForm';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import '../../styles/CommentsSection.css';
import { getUserItems, addUserItem, toggleLike } from '../../services/firestoreService';

const CommentsSection = ({ commentType, itemId, userId, collectionType }) => {
    const { user } = useAuth();
    const { 
        comments, 
        handleDeleteComment,
        handleDeleteReply,
        handleAddReply,
        setComments
    } = useComments(user, commentType, itemId, userId, collectionType);

    const [embedUrl, setEmbedUrl] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [deleteIds, setDeleteIds] = useState({});

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const commentsList = await getUserItems(userId, 'comments');
                setComments(commentsList);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const handleSubmit = async (commentText) => {
        if (!user) {
            console.error('User is not logged in');
            return;
        }
        
        try {
            const newComment = await addUserItem(userId, 'comments', {
                text: commentText,
                embedUrl: embedUrl,
                userId: user.uid,
                userName: user.displayName || 'Anonymous',
                profilePicUrl: user.photoURL || 'default-profile.png',
            });
            setEmbedUrl('');
            setComments(prevComments => [...prevComments, newComment]);
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handleReplySubmit = async (commentId, replyText) => {
        try {
            if (!user) {
                console.error('User is not logged in');
                return;
            }
            const replyData = {
                text: replyText,
                userId: user.uid,
                userName: user.displayName,
                profilePicUrl: user.photoURL || '',
            };
            console.log('Parameters: ', commentId, replyData, user.uid, collectionType);
            await handleAddReply(commentId, replyData, user.uid, commentType);
            setReplyingTo(null);
            setReplyText('');
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };

    const handleDeleteCommentLocal = (commentId) => {
        setDeleteType('comment');
        setDeleteIds({ commentId });
        setOpenDeleteDialog(true);
    };

    const handleToggleLikeLocal = async (commentId) => {
        try {
            const updatedComment = await toggleLike(userId, 'comments', commentId, user.uid);
            setComments(prevComments => prevComments.map(comment => 
                comment.id === commentId ? updatedComment : comment
            ));
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    const handleUrlChange = (url) => {
        let embedCode = '';
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            const videoId = url.split('v=')[1] || url.split('/').pop();
            embedCode = `https://www.youtube.com/embed/${videoId}`;
        } else if (url.includes('vimeo.com')) {
            const videoId = url.split('/').pop();
            embedCode = `https://player.vimeo.com/video/${videoId}`;
        } else if (url.includes('imgur.com')) {
            const imgurRegex = /(?:imgur\.com\/(?:a\/)?(?:gallery\/)?)([\w-]+)(?:\.(?:jpg|jpeg|png|gif))?/i;
            const match = url.match(imgurRegex);
            if (match && match[1]) {
                embedCode = `https://i.imgur.com/${match[1]}.jpg`;
            }
        }
        setEmbedUrl(embedCode);
    };

    const renderEmbed = (url) => {
        if (!url) return null;

        if (url.includes('youtube.com/embed/')) {
            return <iframe width="100%" height="315" src={url} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen className="comment-embed" title="YouTube video player"></iframe>;
        } else if (url.includes('player.vimeo.com/video/')) {
            return <iframe src={url} width="100%" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="comment-embed" title="Vimeo video player"></iframe>;
        } else if (url.includes('i.imgur.com')) {
            return <img src={url} alt="Imgur embedded content" className="comment-embed" />;
        }

        return null;
    };

    const handleDeleteReplyClick = (commentId, replyId) => {
        setDeleteType('reply');
        setDeleteIds({ commentId, replyId });
        setOpenDeleteDialog(true);
    };

    const handleConfirmDelete = async () => {
        if (deleteType === 'comment') {
            try {
                const success = await handleDeleteComment(deleteIds.commentId, userId, collectionType);
                if (!success) {
                    console.error("Failed to delete the comment");
                    alert("Failed to delete the comment. Please try again.");
                }
            } catch (error) {
                console.error("Error deleting the comment:", error);
                alert("An error occurred while deleting the comment. Please try again.");
            }
        } else if (deleteType === 'reply') {
            await handleDeleteReply(deleteIds.commentId, deleteIds.replyId);
        }
        setOpenDeleteDialog(false);
    };

    return (
        <Box className="rainbow-comments-container">
            <PostForm 
                handleSubmit={handleSubmit}
                onUrlChange={handleUrlChange}
            />
            <ul className="comments-list">
                {comments.map((comment) => comment && (
                    <li key={comment.id} className="sparkly-comment-item">
                        <Card className="comment">
                            <CardHeader
                                avatar={<Avatar src={comment.profilePicUrl || comment.user?.photoURL || 'default-profile.png'} className="comment-profile-pic" />}
                                title={
                                    <Typography variant="body2" className="comment-username">
                                        {comment.userName || 'Anonymous'}
                                    </Typography>
                                }
                                subheader={
                                    <Typography variant="caption" className="comment-timestamp">
                                        {comment.createdAt ? new Date(comment.createdAt.seconds * 1000).toLocaleString() : 'Unknown date'}
                                    </Typography>
                                }
                                action={
                                    <Box className="like-container">
                                        <IconButton onClick={() => handleToggleLikeLocal(comment.id)}>
                                            {comment.likes && comment.likes.includes(user?.uid) ? <FavoriteIcon color="secondary" /> : <FavoriteBorderIcon />}
                                        </IconButton>
                                        <Typography variant="caption">{comment.likes ? comment.likes.length : 0}</Typography>
                                    </Box>
                                }
                            />
                            <Divider />
                            <CardContent className="comment-content">
                                <Typography variant="body1" className="comment-text">
                                    {comment.text}
                                </Typography>
                                {comment.embedUrl && renderEmbed(comment.embedUrl)}
                            </CardContent>
                            <Box className="comment-actions">
                                <Typography 
                                    variant="caption" 
                                    className="reply-link" 
                                    onClick={() => setReplyingTo(comment.id)}
                                >
                                    Reply
                                </Typography>
                                {user && user.uid === comment.userId && (
                                    <Typography 
                                        variant="caption" 
                                        className="comment-delete-link" 
                                        onClick={() => handleDeleteCommentLocal(comment.id)}
                                    >
                                        Delete
                                    </Typography>
                                )}
                            </Box>
                            {replyingTo === comment.id && (
                                <Box className="reply-form">
                                    <textarea
                                        className="reply-input"
                                        value={replyText}
                                        onChange={(e) => setReplyText(e.target.value)}
                                        placeholder="Write a reply..."
                                    />
                                    <Box className="reply-actions">
                                        <Button className="reply-submit" onClick={() => handleReplySubmit(comment.id, replyText, userId)}>Submit Reply</Button>
                                        <Button className="reply-cancel" onClick={() => setReplyingTo(null)}>Cancel</Button>
                                    </Box>
                                </Box>
                            )}
                            {comment.replies && comment.replies.map((reply) => (
                                <Box key={reply.id} className="reply-card">
                                    <CardHeader
                                        className="reply-header"
                                        avatar={<Avatar src={reply.profilePicUrl || 'default-profile.png'} className="reply-avatar" />}
                                        title={
                                            <Typography variant="body2" className="reply-author">
                                                {reply.userName}
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography variant="caption" className="reply-date">
                                                {new Date(reply.createdAt?.seconds * 1000).toLocaleString()}
                                            </Typography>
                                        }
                                    />
                                    <CardContent className="reply-body">
                                        <Typography variant="body2" className="reply-text">
                                            {reply.text}
                                        </Typography>
                                    </CardContent>
                                    {user && user.uid === reply.userId && (
                                        <Typography 
                                            variant="caption" 
                                            className="reply-delete-button" 
                                            onClick={() => handleDeleteReplyClick(comment.id, reply.id)}
                                        >
                                            Delete
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </Card>
                    </li>
                ))}
            </ul>
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this {deleteType}? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CommentsSection;