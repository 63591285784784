import { collection, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from './firebase'; // Adjust the path if necessary

// Define each function explicitly
const fetchUserProfile = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
        return userDoc.data();
    } else {
        throw new Error("User not found");
    }
};

const fetchUserEvents = async (userData) => {
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 1 week ago
    const futureEvents = [];
    const pastEvents = [];

    if (Array.isArray(userData.attending)) {
        for (const event of userData.attending) {
            const eventDate = new Date(event.start.seconds * 1000);
            const eventEndDate = new Date(event.end.seconds * 1000);

            if (eventDate > now) {
                futureEvents.push(event);
            } else if (eventEndDate < now && eventEndDate > oneWeekAgo) {
                pastEvents.push(event);
            }

            const locationDoc = await getDoc(doc(firestore, 'locations', event.locationId));
            if (locationDoc.exists()) {
                event.locationName = locationDoc.data().name;
            }
        }
    }
    return { futureEvents, pastEvents };
};

const fetchUserConnections = async (userData) => {
    const following = [];
    const followers = [];

    if (Array.isArray(userData.following)) {
        for (const followId of userData.following) {
            const followDoc = await getDoc(doc(firestore, 'users', followId));
            if (followDoc.exists()) {
                following.push({ uid: followDoc.id, ...followDoc.data() });
            }
        }
    }

    if (Array.isArray(userData.followers)) {
        for (const followerId of userData.followers) {
            const followerDoc = await getDoc(doc(firestore, 'users', followerId));
            if (followerDoc.exists()) {
                followers.push({ uid: followerDoc.id, ...followerDoc.data() });
            }
        }
    }
    return { following, followers };
};

const fetchFavoriteGyms = async (userData) => {
    const favoriteGyms = [];

    if (Array.isArray(userData.favorites)) {
        for (const gymId of userData.favorites) {
            const gymDoc = await getDoc(doc(firestore, 'locations', gymId));
            if (gymDoc.exists()) {
                favoriteGyms.push({ id: gymId, ...gymDoc.data() });
            }
        }
    }
    return favoriteGyms;
};

const fetchUserComments = async (userId) => {
    const commentsRef = collection(firestore, `users/${userId}/comments`);
    const snapshot = await getDocs(commentsRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const handleUserLogin = async (user) => {
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
        // Create user document with role map
        await setDoc(userDocRef, {
            uid: user.uid,
            displayName: user.displayName || '',
            email: user.email || '',
            photoURL: user.photoURL || '',
            role: {
                admin: false,
                moderator: false,
                user: true,
            },
            createdAt: new Date(),
        });
    } else {
        const existingData = userDocSnapshot.data();
        if (!existingData.role) {
            // Add role map if missing
            await setDoc(userDocRef, {
                role: {
                    admin: false,
                    moderator: false,
                    user: true,
                },
            }, { merge: true });
        }
    }
};

const getUserData = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role && typeof userData.role.admin !== 'undefined') {
            // Role exists and is properly formatted
        } else {
            console.error("Role field or admin property is missing in Firestore.");
        }
        return userData;
    } else {
        console.error("No such user!");
        return null;
    }
};

// Dummy user document path
const DUMMY_USER_DOC_PATH = 'users/dummyAccount'; // This is the correct path to the dummy account

// Function to copy roles from the dummy user to the new user
export const copyRolesFromDummyUser = async (newUserUid) => {
    try {
        // Fetch the role map from the dummy user
        const dummyUserRef = doc(firestore, DUMMY_USER_DOC_PATH);
        const dummyUserDoc = await getDoc(dummyUserRef);

        if (dummyUserDoc.exists()) {
            const dummyRoles = dummyUserDoc.data().role;

            // Update the new user's role with the copied role map
            const newUserRef = doc(firestore, 'users', newUserUid);
            await updateDoc(newUserRef, {
                role: dummyRoles,
            });

        } else {
            console.error('Dummy user document does not exist.');
        }
    } catch (error) {
        console.error('Error copying roles from dummy user:', error);
    }
};

export default handleUserLogin;

export {
    getUserData,
    fetchUserProfile,
    fetchUserEvents,
    fetchUserConnections,
    fetchFavoriteGyms,
    fetchUserComments,
    handleUserLogin, // Export the new function
};
