import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../services/firebase';

export const logActivity = async (userId, message, type, details) => {
    if (!userId || !message || !type || !details) {
        console.error("Missing required fields for logging activity.");
        return;
    }

    const activityData = {
        userId,
        message,
        type,
        details,
        timestamp: serverTimestamp(), // Use Firestore server timestamp
    };

    if (details.userPhotoURL) {
        activityData.userPhotoURL = details.userPhotoURL;
    }

    try {
        await addDoc(collection(firestore, "activities"), activityData);
        await addDoc(collection(firestore, `users/${userId}/notifications`), {
            ...activityData,
            read: false
        });
    } catch (error) {
        console.error("Error logging activity: ", error);
    }
};

export const logFollowActivity = async (followerId, followerName, followedId, followedName, followerPhotoURL) => {
    const message = `<a href="/profile/${followerId}"><strong>${followerName}</strong></a> started following you`;
    const details = {
        followerId,
        followerName,
        userPhotoURL: followerPhotoURL || '',
    };
    await logActivity(followedId, message, 'follow', details);
};

export const logUnfollowActivity = async (followerId, followerName, followedId, followedName, followerPhotoURL) => {
    const message = `<a href="/profile/${followerId}"><strong>${followerName}</strong></a> unfollowed you`;
    const details = {
        followerId,
        followerName,
        userPhotoURL: followerPhotoURL || '',
    };
    await logActivity(followedId, message, 'unfollow', details);
};

export const logCommentActivity = async (
    commenterId,
    commenterName,
    commentText,
    commentId,
    userPhotoURL,
    userId
) => {
    if (!commenterId || !commenterName || !commentText || !commentId || !userId) {
        throw new Error("Missing required fields for logging activity.");
    }

    const message = `<a href="/profile/${commenterId}"><strong>${commenterName}</strong></a> commented: "${commentText}"`;
    const details = {
        commenterId,
        commenterName,
        commentText,
        commentId,
        userPhotoURL: userPhotoURL || "",
    };

    await logActivity(userId, message, "comment", details);
};

export const logReplyActivity = async (replierId, replierName, repliedToCommentId, replyText, replierPhotoURL, topCommentUserId, profilePageId) => {
    const message = `<a href="/profile/${replierId}"><strong>${replierName}</strong></a> replied to your <a href="/profile/${profilePageId}#comment-${repliedToCommentId}"><strong>comment</strong></a>: "${replyText}"`;
    const details = {
        replierId,
        replierName,
        replyText,
        userPhotoURL: replierPhotoURL || '',
        repliedToCommentId,
    };
    await logActivity(topCommentUserId, message, 'reply', details);
};

export const logReactionActivity = async (
    reactorId,
    reactorName,
    reactedToCommentId,
    reactionType,
    reactorPhotoURL = '',
    topCommentUserId,
    profilePageId,
    commentText = ''
) => {
    if (!reactorId || !reactorName || !reactedToCommentId || !reactionType || !topCommentUserId || !profilePageId) {
        console.error("Missing required fields for logging activity.");
        return;
    }

    const message = `<a href="/profile/${reactorId}"><strong>${reactorName}</strong></a> ${reactionType} your <a href="/profile/${profilePageId}#comment-${reactedToCommentId}">comment</a>: ${commentText}`;
    const details = {
        reactorId,
        reactorName,
        reactionType,
        userPhotoURL: reactorPhotoURL,
        reactedToCommentId,
    };
    await logActivity(topCommentUserId, message, 'reaction', details);
};


export default logActivity;
