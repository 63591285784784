import React from 'react';
import { 
    Dialog, 
    DialogContent, 
    IconButton, 
    Box, 
    Typography,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../services/firebase';
import logo from '../../assets/OpenMatApp.png';

const WelcomeModal = ({ open, onClose, isLoginRequired = false, intendedPath = null }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            onClose();
            
            // Navigate to intended path if it exists
            if (intendedPath) {
                navigate(intendedPath);
            }
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={isLoginRequired ? undefined : onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                }
            }}
        >
            {!isLoginRequired && (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 3,
                        textAlign: 'center',
                    }}
                >
                    <img 
                        src={logo} 
                        alt="OpenMat Logo" 
                        style={{ 
                            width: '200px', 
                            marginBottom: '20px',
                            height: 'auto',
                        }} 
                    />
                    
                    <Typography variant="h5" component="h2" gutterBottom>
                        {isLoginRequired ? 'Sign In Required' : 'Welcome to OpenMat'}
                    </Typography>
                    
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        {isLoginRequired 
                            ? 'Please sign in to access this feature'
                            : 'Find and join open mat sessions at BJJ gyms near you.'}
                    </Typography>

                    <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGoogleSignIn}
                            sx={{
                                borderRadius: 2,
                                textTransform: 'none',
                            }}
                        >
                            Sign in with Google
                        </Button>
                        {!isLoginRequired && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onClose}
                                sx={{
                                    borderRadius: 2,
                                    textTransform: 'none',
                                }}
                            >
                                Continue Browsing
                            </Button>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default WelcomeModal;
