// src/services/recurrenceService.js

import { doc, getDoc, updateDoc, writeBatch, arrayUnion, collection } from 'firebase/firestore';
import { firestore } from './firebase';
import logActivity from '../utils/logActivity';
import { formatUserLink, formatGymLink } from '../utils/formatLink';

export const saveEventWithRecurrence = async (
    user,
    placeId,
    placeInfo,
    newEventDetails,
    recurrence,
    occurrences,
    setEvents,
    checkAndSetOpenMatStatus,
    setShowRecurrenceModal,
    setNewEventDetails
) => {
    if (!newEventDetails || occurrences < 1) return;

    const count = parseInt(occurrences, 10) || 1;

    const newEvents = [];
    const eventTemplate = {
        ...newEventDetails,
        addedBy: {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
        },
        attendees: [
            {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
            },
        ],
        recurrence: recurrence, // Set the correct recurrence value
    };

    const startDate = new Date(newEventDetails.start);
    const endDate = new Date(newEventDetails.end);



    for (let i = 0; i < count; i++) {
        let eventCopy;
        switch (recurrence) {
            case 'daily':
                eventCopy = {
                    ...eventTemplate,
                    start: new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000),
                    end: new Date(endDate.getTime() + i * 24 * 60 * 60 * 1000),
                };
                break;
            case 'weekly':
                eventCopy = {
                    ...eventTemplate,
                    start: new Date(startDate.getTime() + i * 7 * 24 * 60 * 60 * 1000),
                    end: new Date(endDate.getTime() + i * 7 * 24 * 60 * 60 * 1000),
                };
                break;
            case 'monthly':
                eventCopy = {
                    ...eventTemplate,
                    start: new Date(new Date(startDate).setMonth(startDate.getMonth() + i)),
                    end: new Date(new Date(endDate).setMonth(endDate.getMonth() + i)),
                };
                break;
            default:
                eventCopy = { ...eventTemplate, recurrence: 'none' };
        }
        newEvents.push(eventCopy);
    }

    try {
        const batch = writeBatch(firestore);
        const eventDocRefs = [];

        newEvents.forEach((event) => {
            const eventDocRef = doc(collection(firestore, 'locations', placeId, 'events'));
            batch.set(eventDocRef, event);
            eventDocRefs.push(eventDocRef);
        });

        await batch.commit();

        const savedEventsWithIds = await Promise.all(
            eventDocRefs.map(async (ref, index) => {
                const docSnap = await getDoc(ref);
                return {
                    ...newEvents[index],
                    id: docSnap.id,
                };
            })
        );

        setEvents((prevEvents) => [...prevEvents, ...savedEventsWithIds]);

        checkAndSetOpenMatStatus();

        await updateDoc(doc(firestore, 'users', user.uid), {
            attending: arrayUnion(
                ...savedEventsWithIds.map((event) => ({
                    eventId: event.id,
                    locationId: placeId,
                    title: event.title,
                    start: event.start,
                    end: event.end,
                }))
            ),
        });

        await logActivity(
            user.uid,
            `${formatUserLink(user.uid, user.displayName)} added an event: ${newEventDetails.title} at: ${formatGymLink(
                placeId,
                placeInfo.name
            )}`,
            'event_creation',
            {
                userId: user.uid,
                userName: user.displayName,
                gymId: placeId,
                gymName: placeInfo.name,
                eventId: savedEventsWithIds.map((event) => event.id),
                eventTitle: newEventDetails.title,
                userPhotoURL: user.photoURL,
            }
        );

        setShowRecurrenceModal(false);
        setNewEventDetails(null);
    } catch (error) {
        console.error('Error adding event: ', error);
    }
};
