// src/components/specific/common/JiuJitsuReactions.js
import React from 'react';

const JiuJitsuReactions = ({ itemId }) => {
    return (
        <div className="reactions-container">
            <button className="reaction-button">👍</button>
            <button className="reaction-button">👊</button>
            <button className="reaction-button">🤙</button>
        </div>
    );
};

export default JiuJitsuReactions;
