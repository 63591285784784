import { useEffect, useState } from 'react';
import { fetchUserFeed } from '../services/feedService';

const useFeedData = (user, setFeedItems) => {
    const [hasMoreData, setHasMoreData] = useState(true);
    const [lastFetchedItem, setLastFetchedItem] = useState(null);

    useEffect(() => {
        const fetchFeed = async () => {
            if (!user) {
                console.error('User is undefined');
                return;
            }

            try {
                console.log(`Fetching data for userId: ${user.uid}`);

                const feedData = await fetchUserFeed(user.uid);

                // console.log("Fetched feed data:", feedData);

                // Sort the feed by timestamp
                feedData.sort((a, b) => b.data.timestamp - a.data.timestamp);

                if (feedData.length === 0) {
                    console.warn('No feed data available.');
                }

                setFeedItems(feedData);
                setLastFetchedItem(feedData[feedData.length - 1]);
            } catch (error) {
                console.error('Error fetching feed data:', error);
            }
        };

        fetchFeed();
    }, [user, setFeedItems]);

    const fetchMoreData = async () => {
        if (!lastFetchedItem) {
            setHasMoreData(false);
            return;
        }

        // Additional logic to fetch more data can be added here
    };

    return { fetchMoreData, hasMoreData };
};

export default useFeedData;
