import { useState } from 'react';

export const useRecurrence = () => {
    const [recurrence, setRecurrence] = useState('none');
    const [occurrences, setOccurrences] = useState(1); // Default to 1 occurrence

    const handleRecurrenceChange = (value) => {
        setRecurrence(value);
    };

    const handleOccurrencesChange = (value) => {
        setOccurrences(Math.min(15, Math.max(1, value)));
    };

    return {
        recurrence,
        occurrences,
        setRecurrence: handleRecurrenceChange,
        setOccurrences: handleOccurrencesChange,
    };
};
