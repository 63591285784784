import React, { useEffect, useState } from 'react';
import { auth, googleProvider } from '../services/firebase';
import { signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDoc, setDoc, updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { copyRolesFromDummyUser } from '../services/userProfileService';
import EditProfileModal from '../components/specific/EditProfileModal';
import '../styles/Login.css';
import logo from '../assets/OpenMatApp.png';

const Login = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        if (user) {
            // Navigate back to the page they came from, or home if no previous page
            const from = location.state?.from || '/';
            navigate(from);
        }
    }, [user, navigate, location]);

    const signInWithGoogle = async () => {
        let isMounted = true;

        try {
            console.log('Signing in with Google...');
            const result = await signInWithPopup(auth, googleProvider);
            const loggedInUser = result.user;

            const userDocRef = doc(firestore, 'users', loggedInUser.uid);
            const userDoc = await getDoc(userDocRef);

            const userData = {
                uid: loggedInUser.uid,
                displayName: loggedInUser.displayName,
                email: loggedInUser.email,
                photoURL: loggedInUser.photoURL,
                phoneNumber: loggedInUser.phoneNumber,
                providerId: loggedInUser.providerData[0].providerId,
                role: userDoc.exists() ? userDoc.data().role : 'user',
            };

            if (userDoc.exists()) {
                if (userDoc.data().createdAt) {
                    userData.createdAt = userDoc.data().createdAt;
                }
                await updateDoc(userDocRef, userData);
            } else {
                userData.createdAt = new Date();
                await setDoc(userDocRef, userData);
            }

            if (!userDoc.data()?.role) {
                await copyRolesFromDummyUser(loggedInUser.uid);
            }

            if (isMounted) {
                const userProfile = await getDoc(userDocRef);
                if (!userProfile.data()?.city) {
                    setProfile(userProfile.data());
                    setIsProfileIncomplete(true);
                } else {
                    const from = location.state?.from || '/';
                    navigate(from);
                }
            }
        } catch (error) {
            console.error("Login failed: ", error);
        }

        return () => {
            isMounted = false;
        };
    };

    return (
        <div className="login-container">
            <img src={logo} alt="Open Mat App Logo" className="login-logo" />
            <p className="login-message">
                Please sign in to interact with gyms and access all features
            </p>
            <button onClick={signInWithGoogle} className="login-button">
                Sign in with Google
            </button>

            {isProfileIncomplete && profile && (
                <EditProfileModal
                    profile={profile}
                    onSave={() => {
                        setIsProfileIncomplete(false);
                        const from = location.state?.from || '/';
                        navigate(from);
                    }}
                    onClose={() => {}}
                    canDismiss={false}
                />
            )}
        </div>
    );
};

export default Login;
