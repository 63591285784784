import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import InfiniteScroll from 'react-infinite-scroll-component';
import { auth } from '../../services/firebase';
import useFeedData from '../../hooks/useFeedData';
import CommentFeedItem from './feedItems/CommentFeedItem';
import EventFeedItem from './feedItems/EventFeedItem';
import FollowFeedItem from './feedItems/FollowFeedItem';
import NewUserFeedItem from './feedItems/NewUserFeedItem';
import MilestoneFeedItem from './feedItems/MilestoneFeedItem';
import AchievementFeedItem from './feedItems/AchievementFeedItem';
import MediaPostFeedItem from './feedItems/MediaPostFeedItem';
import TrainingLogFeedItem from './feedItems/TrainingLogFeedItem';
import GymFavoriteFeedItem from './feedItems/GymFavoriteFeedItem';
import GymPinFeedItem from './feedItems/GymPinFeedItem';  // New component
import ReactionFeedItem from './feedItems/ReactionFeedItem';  // New component
import EventDeletedFeedItem from './feedItems/EventDeletedFeedItem';  // New component
import ReplyFeedItem from './feedItems/ReplyFeedItem';  // New component
import '../../styles/Feed.css';

const Feed = () => {
    const [user] = useAuthState(auth);
    const [feedItems, setFeedItems] = useState([]);
    const { fetchMoreData, hasMoreData } = useFeedData(user, setFeedItems);

    if (!user) {
        console.error('User is not authenticated');
        return <div>Please log in to see the feed.</div>;
    }

    const renderFeedItem = (item) => {
        if (!item?.data) {
            console.error('Feed item is missing data:', item);
            return null;
        }

        switch (item.type) {
            case 'comment':
                return <CommentFeedItem key={item.id} data={item.data} />;
            case 'event_created':
                return <EventFeedItem key={item.id} data={item.data} />;
            case 'follow':
                return <FollowFeedItem key={item.id} data={item.data} />;
            case 'new_user':
                return <NewUserFeedItem key={item.id} data={item.data} />;
            case 'milestone':
                return <MilestoneFeedItem key={item.id} data={item.data} />;
            case 'achievement':
                return <AchievementFeedItem key={item.id} data={item.data} />;
            case 'media_post':
                return <MediaPostFeedItem key={item.id} data={item.data} />;
            case 'training_log':
                return <TrainingLogFeedItem key={item.id} data={item.data} />;
            case 'gym_favorite':
                return <GymFavoriteFeedItem key={item.id} data={item.data} />;
            case 'gym_pin':
                return <GymPinFeedItem key={item.id} data={item.data} />;
            case 'reaction':
                return <ReactionFeedItem key={item.id} data={item.data} />;
            case 'event_deleted':
                return <EventDeletedFeedItem key={item.id} data={item.data} />;
            case 'reply':
                return <ReplyFeedItem key={item.id} data={item.data} />;
            default:
                return null;
        }
    };

    return (
        <div className="feed-container">
            <InfiniteScroll
                dataLength={feedItems.length}
                next={fetchMoreData}
                hasMore={hasMoreData}
                loader={<h4>Loading...</h4>}
                className="infinite-scroll-container"
            >
                {feedItems.map((item) => renderFeedItem(item))}
            </InfiniteScroll>
        </div>
    );
};

export default Feed;
