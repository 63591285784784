import React from 'react';
import '../../styles/About.css';

const features = [
    { title: "Map Integration", description: "Easily find gyms and open mat events near you with our integrated Google Maps feature." },
    { title: "Search Functionality", description: "Automatically search for gyms or events nearby based on your location." },
    { title: "User Profiles", description: "Create and customize your profile to track your gym visits, events, and interactions with other users." },
    { title: "Gym & Event Details", description: "View detailed information about gyms and events, including schedules, ratings, and contact info." },
    { title: "Social Features", description: "Connect with other BJJ practitioners, follow gyms, and stay updated with their events." },
    { title: "Notifications", description: "Receive alerts about upcoming events, gym updates, and interactions from other users." },
];

const howToSteps = [
    { step: "Sign Up / Log In", description: "Start by creating an account or logging in to access all features." },
    { step: "Find Gyms", description: "Use the map to search for gyms near your location. Click on markers to view detailed gym information." },
    { step: "Explore Events", description: "Browse upcoming open mat sessions and events, and RSVP directly through the app." },
    { step: "Customize Your Profile", description: "Update your profile with your BJJ rank, gym affiliations, and more." },
    { step: "Stay Connected", description: "Follow gyms and events to receive updates and notifications." },
];

const About = () => {
    return (
        <div className="about-container">
            <h1 className="about-title">About Open Mat App</h1>
            <p className="about-description">
                Welcome to the Open Mat App! Our mission is to help Brazilian Jiu-Jitsu enthusiasts find and connect with gyms and open mat events around them. Whether you're looking to attend a new gym, find an open mat session, or connect with fellow practitioners, our app has got you covered.
            </p>

            <section className="features-section">
                <h2 className="section-title">Features</h2>
                <ul className="features-list">
                    {features.map((feature, index) => (
                        <li key={index} className="feature-item">
                            <strong className="feature-title">{feature.title}:</strong>
                            <span className="feature-description">{feature.description}</span>
                        </li>
                    ))}
                </ul>
            </section>

            <section className="how-to-section">
                <h2 className="section-title">How to Use</h2>
                <ol className="how-to-list">
                    {howToSteps.map((step, index) => (
                        <li key={index} className="how-to-item">
                            <strong className="how-to-step">{step.step}:</strong>
                            <span className="how-to-description">{step.description}</span>
                        </li>
                    ))}
                </ol>
            </section>

            <section className="contact-section">
                <h2 className="section-title">Contact Us</h2>
                <p className="contact-description">
                    If you have any questions or feedback, feel free to contact us using our
                    <a href="https://openmatapp.com/contact" className="contact-link"> contact page</a>.
                    We are always looking to improve and appreciate your input!
                </p>
            </section>
        </div>
    );
};

export default About;
