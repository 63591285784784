import React from 'react';

const EventDeletedFeedItem = ({ data }) => {
    return (
        <div className="feed-item event-deleted-feed-item">
            <div className="feed-item-header">
                <h3>An event was deleted</h3>
            </div>
            <p>Event: {data.eventTitle}</p>
            <p>Deleted by: {data.deletedBy}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
        </div>
    );
};

export default EventDeletedFeedItem;
