import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../services/firebase';
import JiuJitsuReactions from '../../common/JiuJitsuReactions';

const CommentFeedItem = ({ data }) => {
    const [displayName, setDisplayName] = useState('');
    const [userPhotoURL, setUserPhotoURL] = useState('');
    const [commenterPhotoURL, setCommenterPhotoURL] = useState('');

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (data?.userId) {
                const userDoc = await getDoc(doc(firestore, 'users', data.userId));
                if (userDoc.exists()) {
                    setDisplayName(userDoc.data().displayName || 'another user');
                    setUserPhotoURL(userDoc.data().photoURL || '');
                } else {
                    setDisplayName('another user');
                }
            }

            if (data?.details?.commenterId) {
                const commenterDoc = await getDoc(doc(firestore, 'users', data.details.commenterId));
                if (commenterDoc.exists()) {
                    setCommenterPhotoURL(commenterDoc.data().photoURL || '');
                }
            }
        };

        fetchUserInfo();
    }, [data?.userId, data?.details?.commenterId]);

    // Log the data for debugging purposes
    console.log('CommentFeedItem data:', data);

    if (!data || !data.details) {
        return <div>Error: Comment data is not available.</div>;
    }

    return (
        <div className="feed-item">
            <div className="feed-item-header">
                <h3>
                    {data.details.isSelfPost ? (
                        <>
                            <Link to={`/profile/${data.details.commenterId}`}>
                                {commenterPhotoURL && (
                                    <img
                                        src={commenterPhotoURL}
                                        alt={`${data.details.commenterName}'s profile`}
                                        className="profile-pic"
                                    />
                                )}
                                {data.details.commenterName || 'A user'}
                            </Link>
                            {' '}posted:
                        </>
                    ) : (
                        <>
                            <Link to={`/profile/${data.details.commenterId}`}>
                                {commenterPhotoURL && (
                                    <img
                                        src={commenterPhotoURL}
                                        alt={`${data.details.commenterName}'s profile`}
                                        className="profile-pic"
                                    />
                                )}
                                {data.details.commenterName || 'A user'}
                            </Link>
                            {' '}posted to{' '}
                            <Link to={`/profile/${data.userId}`}>
                                {userPhotoURL && (
                                    <img
                                        src={userPhotoURL}
                                        alt={`${displayName}'s profile`}
                                        className="profile-pic"
                                    />
                                )}
                                {displayName || 'another user'}
                            </Link>'s profile:
                        </>
                    )}
                </h3>
            </div>
            <p>"{data.details.commentText}"</p>
            <p>On {new Date(data.timestamp?.toDate()).toLocaleDateString()}</p>
            <JiuJitsuReactions itemId={data.id} />
        </div>
    );
};

export default CommentFeedItem;
