import React from 'react';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const navigate = useNavigate(); // Using the useNavigate hook

    return (
        <div className="settings">
            <h3>Admin Settings</h3>
                <button
                    className="admin-dashboard-action-button"
                    onClick={() => navigate('/manage-roles')} // Correctly using navigate
                >
                    Manage Roles
                </button>
            <button className="settings-button">System Settings</button>
        </div>
    );
};

export default Settings;
