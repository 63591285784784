// src/hooks/useAppLogic.js
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, checkAndCreateUserProfile } from '../services/firebase';
import { isIOS, isSafari } from '../utils/browserDetection';

export const useAppLogic = () => {
    const [user, loading] = useAuthState(auth);
    const [isNewUser, setIsNewUser] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [isIos, setIsIos] = useState(false);
    const [showIosInstallInstructions, setShowIosInstallInstructions] = useState(false);
    const [showLocationDeniedModal, setShowLocationDeniedModal] = useState(false);

    useEffect(() => {
        const checkProfile = async () => {
            if (user) {
                const userIsNew = await checkAndCreateUserProfile(user);
                setIsNewUser(userIsNew);
            }
        };

        checkProfile().catch((error) => console.error('Error checking/creating user profile:', error));
    }, [user]);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            if (!localStorage.getItem('installPromptDismissed')) {
                setShowInstallButton(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(userAgent)) {
            setIsIos(true);
            if (!localStorage.getItem('iosInstallPromptDismissed')) {
                setShowIosInstallInstructions(true);
            }
        }

        const checkLocationAccess = () => {
            navigator.geolocation.getCurrentPosition(
                () => setShowLocationDeniedModal(false),
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        setShowLocationDeniedModal(true);
                    }
                }
            );
        };

        if (isIOS() && isSafari()) {
            checkLocationAccess();
        }

        const intervalId = setInterval(checkLocationAccess, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
                localStorage.setItem('installPromptDismissed', 'true');
            }
            setDeferredPrompt(null);
            setShowInstallButton(false);
        }
    };

    const handleDismissIosInstallInstructions = () => {
        setShowIosInstallInstructions(false);
        localStorage.setItem('iosInstallPromptDismissed', 'true');
    };

    const handleCloseLocationDeniedModal = () => {
        setShowLocationDeniedModal(false);
    };

    return {
        user,
        loading,
        isNewUser,
        isIos,
        showInstallButton,
        showIosInstallInstructions,
        showLocationDeniedModal,
        handleInstallClick,
        handleDismissIosInstallInstructions,
        handleCloseLocationDeniedModal,
    };
};
