import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAppLogic } from './hooks/useAppLogic';
import Notifications from './components/specific/Notifications';
import Login from './pages/Login';
import Header from './components/layout/Header';
import GymInfo from './components/specific/GymInfo';
import UserProfile from './components/specific/UserProfile.js';
import About from './components/specific/About.js';
import ContactForm from './components/specific/ContactForm.js';
import EventInfo from './components/specific/EventInfo.js';
import MapContainer from './components/specific/MapContainer.js';
import Feed from './components/specific/Feed';
import Sidebar from './components/specific/Sidebar.js';
import { MapProvider } from './context/MapContext';
import Logo from './components/common/Logo.js';
import LocationDeniedModal from './components/specific/LocationDeniedModal.js';
import WelcomeModal from './components/specific/WelcomeModal';
import './styles/styles.css';
import './styles/common/Logo.css';
import { UserProvider } from "./context/UserContext";
import AdminDashboard from './components/admin/AdminDashboard';
import ManageRoles from "./components/admin/ManageRoles";
import UserEventInfo from './components/specific/UserEventInfo';
import TestProfile from "./components/specific/TestProfile";
import { useAuth } from './hooks/useAuth';
import ProtectedLayout from './components/layout/ProtectedLayout';

const PrivateRoute = ({ children }) => {
    const { user, showLoginModal, setShowLoginModal, requireAuth } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            requireAuth(location.pathname);
        }
    }, [user, location.pathname, requireAuth]);

    if (!user) {
        return (
            <>
                <WelcomeModal 
                    open={showLoginModal}
                    onClose={() => setShowLoginModal(false)}
                    isLoginRequired={true}
                    intendedPath={location.pathname}
                />
                <PublicMapContainerWithSidebar />
            </>
        );
    }

    return <ProtectedLayout>{children}</ProtectedLayout>;
};

const PublicMapContainerWithSidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { showWelcomeModal, dismissWelcomeModal } = useAuth();
    
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <MapProvider>
            <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} places={[]} onSelectPlace={() => {}} />
            <MapContainer isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <WelcomeModal 
                open={showWelcomeModal} 
                onClose={dismissWelcomeModal} 
            />
        </MapProvider>
    );
};

const PrivateMapContainerWithSidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <MapProvider>
            <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} places={[]} onSelectPlace={() => {}} />
            <MapContainer isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </MapProvider>
    );
};

const App = () => {
    const {
        loading,
        isIos,
        showInstallButton,
        showIosInstallInstructions,
        showLocationDeniedModal,
        handleInstallClick,
        handleDismissIosInstallInstructions,
        handleCloseLocationDeniedModal,
    } = useAppLogic();

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<PublicMapContainerWithSidebar />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
                    <Route path="/find-gym" element={<PrivateRoute><PrivateMapContainerWithSidebar /></PrivateRoute>} />
                    <Route path="/gym/:id" element={<PrivateRoute><GymInfo /></PrivateRoute>} />
                    <Route path="/profile/:userId/event/:eventId" element={<PrivateRoute><UserEventInfo /></PrivateRoute>} />
                    <Route path="/gym/:gymId/event/:eventId" element={<PrivateRoute><EventInfo /></PrivateRoute>} />
                    <Route path="/profile/:userId" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                    <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                    <Route path="/contact" element={<PrivateRoute><ContactForm /></PrivateRoute>} />
                    <Route path="/feed" element={<PrivateRoute><Feed /></PrivateRoute>} />
                    <Route path="/admin-dashboard" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
                    <Route path="/manage-roles" element={<PrivateRoute><ManageRoles /></PrivateRoute>} />
                </Routes>
                
                <Logo />
                {showInstallButton && !isIos && (
                    <button id="install-button" onClick={handleInstallClick}>
                        Install App
                    </button>
                )}
                {isIos && showIosInstallInstructions && (
                    <div className="ios-install-instructions">
                        <p>To install this app on your iPhone/iPad, in Safari tap the Share icon<img src="/Ei-share-apple.svg" alt="Share" />and then Add to Home Screen.</p>
                        <button onClick={handleDismissIosInstallInstructions}>Close</button>
                    </div>
                )}
                {showLocationDeniedModal && (
                    <LocationDeniedModal onClose={handleCloseLocationDeniedModal} />
                )}
            </Router>
        </UserProvider>
    );
};

export default App;
