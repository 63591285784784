import { collection, addDoc, updateDoc, deleteDoc, doc, getDoc, getDocs, query, orderBy, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../services/firebase'; // Adjust this import path as needed

const handleFirestoreError = (error, operation) => {
  console.error(`Error ${operation}:`, error);
  throw error;
};

// Generic function to add an item to a user's collection
export const addUserItem = async (userId, collectionType, itemData) => {
  try {
    const collectionRef = collection(firestore, `/users/${userId}/${collectionType}`);
    const docRef = await addDoc(collectionRef, {
      ...itemData,
      createdAt: serverTimestamp(),
    });
    const newDoc = await getDoc(docRef);
    return { id: newDoc.id, ...newDoc.data() };
  } catch (error) {
    handleFirestoreError(error, 'adding user item');
  }
};

// Generic function to get all items from a user's collection
export const getUserItems = async (userId, collectionType) => {
  try {
    const collectionRef = collection(firestore, `/users/${userId}/${collectionType}`);
    const q = query(collectionRef, orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    handleFirestoreError(error, 'getting user items');
  }
};

// Generic function to update an item in a user's collection
export const updateUserItem = async (userId, collectionType, itemId, updateData) => {
  try {
    const docRef = doc(firestore, `/users/${userId}/${collectionType}`, itemId);
    await updateDoc(docRef, updateData);
    const updatedDoc = await getDoc(docRef);
    return { id: updatedDoc.id, ...updatedDoc.data() };
  } catch (error) {
    handleFirestoreError(error, 'updating user item');
  }
};

// Generic function to delete an item from a user's collection
export const deleteUserItem = async (collectionType, userId, itemId) => {
  try {
    const itemRef = doc(firestore, `${collectionType}`, itemId);
    await deleteDoc(itemRef);
  } catch (error) {
    console.error('Error deleting user item:', error);
    throw error;
  }
};

// Function to toggle like on an item
export const toggleLike = async (userId, collectionType, itemId, currentUserId) => {
  try {
    const docRef = doc(firestore, `/users/${userId}/${collectionType}`, itemId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Document does not exist');
    }

    const data = docSnap.data();
    const likes = data.likes || [];
    const updatedLikes = likes.includes(currentUserId)
      ? likes.filter(id => id !== currentUserId)
      : [...likes, currentUserId];
    
    await updateDoc(docRef, { likes: updatedLikes });
    return { id: docSnap.id, ...data, likes: updatedLikes };
  } catch (error) {
    handleFirestoreError(error, 'toggling like');
  }
};
