import React from 'react';
import { Link } from 'react-router-dom';

const MilestoneFeedItem = ({ data }) => {
    return (
        <div className="feed-item milestone-feed-item">
            <div className="feed-item-header">
                <h3>🎉 {data.displayName} reached a milestone!</h3>
            </div>
            <p>{data.milestone}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
            <div className="feed-item-footer">
                <Link to={`/profile/${data.userId}`} className="view-profile-button">View Profile</Link>
                <button className="congrats-button">Congratulate</button>
            </div>
        </div>
    );
};

export default MilestoneFeedItem;
