import React from 'react';
import { Link } from 'react-router-dom';

const GymFavoriteFeedItem = ({ data }) => {
    if (!data?.details) {
        return <div>Error: Gym favorite data is not available.</div>;
    }

    return (
        <div className="feed-item gym-favorite-feed-item">
            <div className="feed-item-header">
                <h3>
                    <Link to={`/profile/${data.details.userId}`}>
                        {data.details.userPhotoURL && (
                            <img
                                src={data.details.userPhotoURL}
                                alt={`${data.details.userName}'s profile`}
                                className="profile-pic"
                            />
                        )}
                        {data.details.userName || 'A user'}
                    </Link>{' '}
                    favorited a gym!
                </h3>
            </div>
            <p>
                Gym:{' '}
                <Link to={`/gym/${data.details.gymId}`}>
                    {data.details.gymName}
                </Link>
            </p>
            <p>
                On {new Date(data.timestamp.toDate()).toLocaleDateString()}
            </p>
        </div>
    );
};

export default GymFavoriteFeedItem;
