// utils/userActions.js
import { firestore } from '../services/firebase';
import { doc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';

export const followUser = async (currentUserId, followedUserId, currentUserName, followedUserName) => {
    try {
        const activityRef = doc(firestore, 'users', currentUserId, 'activities', `${followedUserId}_follow`);
        await setDoc(activityRef, {
            type: 'follow',
            userName: currentUserName,
            targetUser: {
                uid: followedUserId,
                displayName: followedUserName,
            },
            timestamp: new Date(),
        });

        await updateDoc(doc(firestore, 'users', currentUserId), {
            following: arrayUnion(followedUserId)
        });

    } catch (error) {
        console.error('Error following user:', error);
    }
};
