import React from 'react';
import '../../styles/EventLengthAlert.css';

const EventLengthAlert = ({ message, onClose }) => {
    return (
        <div className="event-length-alert-overlay">
            <div className="event-length-alert-box">
                <p>{message}</p>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default EventLengthAlert;
