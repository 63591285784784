import { createContext, useState } from 'react';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [previousPosition, setPreviousPosition] = useState(null);
    const [previousSelectedPlace, setPreviousSelectedPlace] = useState(null);

    return (
        <MapContext.Provider value={{
            selectedPosition,
            setSelectedPosition,
            selectedPlace,
            setSelectedPlace,
            previousPosition,
            setPreviousPosition,
            previousSelectedPlace,
            setPreviousSelectedPlace
        }}>
            {children}
        </MapContext.Provider>
    );
};
