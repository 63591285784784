import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, getDoc, updateDoc, deleteDoc, arrayUnion, writeBatch, arrayRemove } from 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';
import CommentsSection from './CommentsSection';
import InviteButton from './InviteButton';
import logActivity from '../../utils/logActivity';
import { formatUserLink, formatGymLink, formatEventLink } from '../../utils/formatLink';
import Modal from './Modal';
import '../../styles/EventInfo.css';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';

const EventInfo = () => {
    const { gymId, eventId } = useParams();
    const [user] = useAuthState(auth);
    const [gym, setGym] = useState(null);
    const [event, setEvent] = useState(null);
    const [isAttending, setIsAttending] = useState(false);
    const [comments, setComments] = useState([]);
    const [playlistEmbedCode, setPlaylistEmbedCode] = useState('');
    const [newPlaylistShareLink, setNewPlaylistShareLink] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            if (gymId && eventId) {
                const eventDoc = await getDoc(doc(firestore, `locations/${gymId}/events`, eventId));
                if (eventDoc.exists()) {
                    setEvent({
                        ...eventDoc.data(),
                        locationId: gymId,
                        id: eventId
                    });
                    setPlaylistEmbedCode(eventDoc.data().playlistEmbedCode || '');
                } else {
                    console.error('No such event!');
                    setShowModal(true);
                }
            }
        };

        const fetchGymInfo = async () => {
            if (gymId) {
                const gymDoc = await getDoc(doc(firestore, 'locations', gymId));
                if (gymDoc.exists()) {
                    setGym(gymDoc.data());
                } else {
                    console.error('No such gym!');
                    setShowModal(true);
                }
            }
        };

        fetchEvent();
        fetchGymInfo();
    }, [gymId, eventId]);

    useEffect(() => {
        if (event && user) {
            const attending = event.attendees.some(attendee => attendee.uid === user.uid);
            setIsAttending(attending);
        }
    }, [event, user]);

    useEffect(() => {
        const fetchComments = async () => {
            const commentsRef = collection(firestore, `locations/${gymId}/events/${eventId}/comments`);
            const snapshot = await getDocs(commentsRef);
            const commentsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            for (let comment of commentsList) {
                const repliesRef = collection(firestore, `locations/${gymId}/events/${eventId}/comments/${comment.id}/replies`);
                const repliesSnapshot = await getDocs(repliesRef);
                comment.replies = repliesSnapshot.docs.map(replyDoc => ({ id: replyDoc.id, ...replyDoc.data() }));
            }

            setComments(commentsList);
        };

        fetchComments();
    }, [gymId, eventId]);

    const toggleAttendance = async () => {
        if (!user || !event) return;

        const eventDocRef = doc(firestore, 'locations', gymId, 'events', eventId);
        const userRef = doc(firestore, 'users', user.uid);

        try {
            if (isAttending) {
                const eventSnapshot = await getDoc(eventDocRef);
                if (eventSnapshot.exists()) {
                    const eventData = eventSnapshot.data();
                    const newAttendees = eventData.attendees.filter(att => att.uid !== user.uid);

                    await updateDoc(eventDocRef, {
                        attendees: newAttendees
                    });
                }

                const userSnapshot = await getDoc(userRef);
                if (userSnapshot.exists()) {
                    const userData = userSnapshot.data();
                    const newAttending = userData.attending.filter(evt => evt.eventId !== eventId);

                    await updateDoc(userRef, {
                        attending: newAttending
                    });
                }

                await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} left the event: ${formatEventLink(gymId, eventId, event.title)} at: ${formatGymLink(gymId, gym.name)}`, 'event_left', {
                    userId: user.uid,
                    userName: user.displayName,
                    gymId: gymId,
                    gymName: gym.name,
                    eventId: eventId,
                    eventTitle: event.title,
                    userPhotoURL: user.photoURL
                });
            } else {
                await updateDoc(eventDocRef, {
                    attendees: arrayUnion({
                        uid: user.uid,
                        displayName: user.displayName,
                        email: user.email,
                        photoURL: user.photoURL
                    })
                });

                await updateDoc(userRef, {
                    attending: arrayUnion({
                        eventId: eventId,
                        locationId: gymId,
                        title: event.title,
                        start: event.start,
                        end: event.end
                    })
                });

                await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} joined the event: ${formatEventLink(gymId, eventId, event.title)} at: ${formatGymLink(gymId, gym.name)}`, 'event_joined', {
                    userId: user.uid,
                    userName: user.displayName,
                    gymId: gymId,
                    gymName: gym.name,
                    eventId: eventId,
                    eventTitle: event.title,
                    userPhotoURL: user.photoURL
                });
            }
        } catch (error) {
            console.error('Error updating attendance:', error.message);
        }

        setIsAttending(!isAttending);
    };

    const handleDeleteEvent = async () => {
        // Confirm deletion with the user
        const confirmDelete = window.confirm('Are you sure you want to delete this event?');
        if (!confirmDelete) return;

        // Ensure the user is the creator of the event
        if (user?.uid !== event.addedBy?.uid) {
            alert('You do not have permission to delete this event.');
            return;
        }

        const eventDocRef = doc(firestore, 'locations', gymId, 'events', eventId);
        try {
            // Start a batch to perform multiple writes as a single atomic operation
            const batch = writeBatch(firestore);

            // Remove the event from each user's attending array
            for (let attendee of event.attendees) {
                const userDocRef = doc(firestore, 'users', attendee.uid);
                batch.update(userDocRef, {
                    attending: arrayRemove({
                        eventId: eventId,
                        locationId: gymId,
                        title: event.title,
                        start: event.start,
                        end: event.end
                    })
                });
            }

            // Delete the event document from Firestore
            batch.delete(eventDocRef);

            // Commit the batch
            await batch.commit();

            // Log the activity of event deletion
            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} deleted the event: ${formatEventLink(gymId, eventId, event.title)} at: ${formatGymLink(gymId, gym.name)}`, 'event_deleted', {
                userId: user.uid,
                userName: user.displayName,
                gymId: gymId,
                gymName: gym.name,
                eventId: eventId,
                eventTitle: event.title,
                userPhotoURL: user.photoURL
            });

            // Navigate back to the gym page after deletion
            navigate(`/gym/${gymId}`);
        } catch (error) {
            // Handle any errors that occur during deletion
            console.error('Error deleting event:', error.message);
            alert('Error deleting event: ' + error.message);
        }
    };

    const handleSavePlaylist = async () => {
        if (!user || !event) return;

        const eventDocRef = doc(firestore, 'locations', gymId, 'events', eventId);

        const embedLink = newPlaylistShareLink.replace('https://open.spotify.com/', 'https://open.spotify.com/embed/');
        const embedCode = `<iframe style="border-radius:12px" src="${embedLink}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;

        try {
            await updateDoc(eventDocRef, {
                playlistEmbedCode: embedCode
            });
            setPlaylistEmbedCode(embedCode);
            setNewPlaylistShareLink('');

            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} updated the playlist for: ${formatEventLink(gymId, eventId, event.title)} at: ${formatGymLink(gymId, gym.name)}`, 'playlist_updated', {
                userId: user.uid,
                userName: user.displayName,
                gymId: gymId,
                gymName: gym.name,
                eventId: eventId,
                eventTitle: event.title,
                userPhotoURL: user.photoURL
            });
        } catch (error) {
            console.error('Error saving playlist:', error.message);
        }
    };

    const handleRemovePlaylist = async () => {
        if (!user || !event) return;

        const eventDocRef = doc(firestore, 'locations', gymId, 'events', eventId);

        try {
            await updateDoc(eventDocRef, {
                playlistEmbedCode: ''
            });
            setPlaylistEmbedCode('');

            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} removed the playlist for: ${formatEventLink(gymId, eventId, event.title)} at: ${formatGymLink(gymId, gym.name)}`, 'playlist_removed', {
                userId: user.uid,
                userName: user.displayName,
                gymId: gymId,
                gymName: gym.name,
                eventId: eventId,
                eventTitle: event.title,
                userPhotoURL: user.photoURL
            });
        } catch (error) {
            console.error('Error removing playlist:', error.message);
        }
    };

    if (showModal) {
        return (
            <Modal
                title="Error"
                content="Event or Gym not found. Please check the URL or try again later."
                onClose={() => navigate('/')}
            />
        );
    }

    if (!event || !gym) {
        return <div>Loading...</div>;
    }

    const shareUrl = window.location.href;
    const shareTitle = `Join me at ${event.title} on ${new Date(event.start.toDate()).toLocaleDateString()}!`;

    return (
        <div className="event-body-content">
            <div className="event-info-container">
                <div className="event-header-card">
                    <div className="event-header-left">
                        <h1>{event.title}</h1>
                        <p>Hosted by <Link to={`/profile/${event.addedBy.uid}`}>{event.addedBy.displayName}</Link></p>
                        <p>{new Date(event.start.toDate()).toLocaleString()} - {new Date(event.end.toDate()).toLocaleString()}</p>
                        <p>{gym.name} - {gym.address}</p>
                    </div>
                    <div className="event-header-right">
                        <h3>Share This Event</h3>
                        <div className="event-share-icons">
                            <FacebookShareButton url={shareUrl} quote={shareTitle}>
                                <FacebookIcon size={32} round={true}/>
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={shareTitle}>
                                <TwitterIcon size={32} round={true}/>
                            </TwitterShareButton>
                            <WhatsappShareButton url={shareUrl} title={shareTitle}>
                                <WhatsappIcon size={32} round={true}/>
                            </WhatsappShareButton>
                            {/* Add more social sharing buttons here if needed */}
                        </div>
                    </div>

                </div>

                <div className="event-attendees-card">
                    <h3>Attendees</h3>
                    <ul className="event-attendees-list">
                        {event.attendees.map(attendee => (
                            <li key={attendee.uid} className="event-attendee-item">
                                <Link to={`/profile/${attendee.uid}`} className="event-attendee-link">
                                    <div className="event-attendee-content">
                                        <img src={attendee.photoURL || 'placeholder.png'} alt="Attendee"
                                             className="event-attendee-pic"/>
                                        <span className="event-attendee-name">{attendee.displayName}</span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                        <div className="event-actions">
                            <InviteButton
                                event={event}
                                gymName={gym.name}
                                inviteType="event"
                            />
                            <button onClick={toggleAttendance}
                                    className={`event-button ${isAttending ? 'event-unattend-button' : 'event-attend-button'}`}>
                                {isAttending ? 'Leave Event' : 'Join Event'}
                            </button>
                            {user?.uid === event.addedBy.uid && (
                                <button onClick={handleDeleteEvent} className="event-delete-button">Delete Event</button>
                            )}
                        </div>
                    </ul>
                </div>
                <CommentsSection
                    uniqueId={eventId}
                    collectionType={`locations/${gymId}/events/${eventId}/comments`}
                    comments={comments}
                    setComments={setComments}
                    currentUser={user}
                    commentType="event" 
                    itemId={`${gymId}|${eventId}`}
                    userId={user?.uid}
                />
                <div className="event-playlist-card">
                    <h3>Collaborative Spotify Playlist</h3>
                    {playlistEmbedCode ? (
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: playlistEmbedCode }} />
                            <button className="event-remove-button" onClick={handleRemovePlaylist}>Remove Playlist</button>
                        </div>
                    ) : (
                        <div>
                            <p>To add a collaborative Spotify playlist:</p>
                            <ol>
                                <li>Create a new playlist or select an existing one in Spotify.</li>
                                <li>Make the playlist collaborative by clicking the three dots, then select "Collaborative Playlist".</li>
                                <li>Click the three dots again, go to "Share", and select "Copy link to playlist".</li>
                                <li>Paste the share link below:</li>
                            </ol>
                            <textarea
                                value={newPlaylistShareLink}
                                onChange={(e) => setNewPlaylistShareLink(e.target.value)}
                                rows="4"
                                placeholder="Enter your share link here..."
                            />
                            <button className="event-save-button" onClick={handleSavePlaylist}>Save Playlist</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventInfo;