// src/components/modals/LocationDeniedModal.js
import React, { useEffect } from 'react';
import '../../styles/LocationDeniedModal.css'; // Updated path to the CSS file

const LocationDeniedModal = ({ onClose }) => {
    useEffect(() => {
        const modalDismissed = localStorage.getItem('locationDeniedModalDismissed');
        if (modalDismissed === 'true') {
            onClose();
        }
    }, [onClose]);

    const handleClose = () => {
        localStorage.setItem('locationDeniedModalDismissed', 'true'); // Mark the modal as dismissed
        onClose();
        window.location.reload(); // Refresh the page
    };

    return (
        <div className="location-denied-modal-overlay">
            <div className="location-denied-modal-content">
                <h2>Location Access Denied</h2>
                <p>
                    Location access is denied. Please enable location services for Safari
                    in your device settings: <br />
                    <strong>Settings &gt; Privacy & Security &gt; Location Services &gt; Safari Websites</strong>
                </p>
                <button onClick={handleClose} className="location-denied-close-button">Close</button>
            </div>
        </div>
    );
};

export default LocationDeniedModal;
