import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { collection, query, orderBy, onSnapshot, updateDoc, doc, getDocs, limit, startAfter, writeBatch, where } from 'firebase/firestore';
import '../../styles/ActivityFeed.css';
import { useNavigate } from 'react-router-dom';

const Notifications = () => {
    const [user] = useAuthState(auth);
    const [activities, setActivities] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();
    let isMounted = true; // Track if the component is mounted

    const fetchActivitiesAndNotifications = async (loadMore = false) => {
        if (!user) return;

        setLoading(true);

        let activitiesQuery = query(
            collection(firestore, `users/${user.uid}/notifications`),
            orderBy('timestamp', 'desc'),
            limit(10)
        );

        if (loadMore && lastVisible) {
            activitiesQuery = query(
                collection(firestore, `users/${user.uid}/notifications`),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(10)
            );
        }

        try {
            const activitiesSnapshot = await getDocs(activitiesQuery);
            if (!isMounted) return; // Avoid setting state if component is unmounted

            const activitiesList = activitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            if (loadMore) {
                setActivities(prevActivities => [...prevActivities, ...activitiesList]);
            } else {
                setActivities(activitiesList);
            }

            setLastVisible(activitiesSnapshot.docs[activitiesSnapshot.docs.length - 1]);
            setHasMore(activitiesSnapshot.docs.length === 10);
        } catch (error) {
            if (isMounted) console.error('Error fetching activities:', error);
        } finally {
            if (isMounted) setLoading(false);
        }
    };

    useEffect(() => {
        fetchActivitiesAndNotifications();
        return () => {
            isMounted = false; // Mark as unmounted when component is about to unmount
        };
    }, [user]);

    const markAsRead = async (notificationId) => {
        if (!user) return;

        try {
            const notificationDoc = doc(firestore, `users/${user.uid}/notifications`, notificationId);
            await updateDoc(notificationDoc, { read: true });

            if (isMounted) {
                setActivities(prevActivities =>
                    prevActivities.map(activity =>
                        activity.id === notificationId ? { ...activity, read: true } : activity
                    )
                );
            }
        } catch (error) {
            if (isMounted) console.error("Error marking notification as read: ", error);
        }
    };

    const markAllAsRead = async () => {
        if (!user) return;

        try {
            const notificationsRef = collection(firestore, `users/${user.uid}/notifications`);
            const unreadNotificationsQuery = query(notificationsRef, where('read', '==', false));
            const unreadNotificationsSnapshot = await getDocs(unreadNotificationsQuery);

            const batch = writeBatch(firestore);
            unreadNotificationsSnapshot.docs.forEach(doc => {
                batch.update(doc.ref, { read: true });
            });

            await batch.commit();

            if (isMounted) {
                setActivities(prevActivities =>
                    prevActivities.map(activity => ({ ...activity, read: true }))
                );
            }
        } catch (error) {
            if (isMounted) console.error("Error marking all notifications as read: ", error);
        }
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            markAllAsRead();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            isMounted = false; // Mark as unmounted when component is about to unmount
            markAllAsRead();
        };
    }, [user]);

    const renderMessage = (item) => {
        return <span dangerouslySetInnerHTML={{ __html: item.message }} />;
    };

    return (
        <div className="activity-feed-container">
            <h2>Notifications</h2>
            <ul className="activity-feed-list">
                {activities.map((activity, index) => (
                    <li key={index} className={`activity-feed-item ${activity.isNotification && !activity.read ? 'unread' : ''}`}>
                        <div className="activity-header">
                            {activity.userPhotoURL && (
                                <img src={activity.userPhotoURL} alt="User" className="activity-user-photo" />
                            )}
                            <p>{renderMessage(activity)}</p>
                        </div>
                        <span className="timestamp">{new Date(activity.timestamp.toMillis()).toLocaleString()}</span>
                        {activity.isNotification && !activity.read && (
                            <a href="#" onClick={(e) => { e.preventDefault(); markAsRead(activity.id); }}>Mark as Read</a>
                        )}
                    </li>
                ))}
            </ul>
            {loading && (
                <button className="load-more-button" disabled>
                    Loading...
                </button>
            )}
            {!loading && hasMore && (
                <button
                    className="load-more-button"
                    onClick={() => fetchActivitiesAndNotifications(true)}
                    disabled={loading}
                >
                    Load More
                </button>
            )}
        </div>
    );
};

export default Notifications;
