import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../services/firebase.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Header.css';

const Header = ({ toggleSidebar, isSidebarOpen }) => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (!user) return;

        const notificationsRef = collection(firestore, `users/${user.uid}/notifications`);
        const notificationsQuery = query(notificationsRef, where('read', '==', false));
        const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
            setUnreadCount(snapshot.docs.length);
        });

        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        const checkAdminRole = async () => {
            if (user) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsAdmin(!!userData.role?.admin);
                } else {
                    console.error("User document does not exist.");
                }
            }
        };

        checkAdminRole();
    }, [user]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleMenuClick = (path) => {
        setIsDropdownOpen(false);
        navigate(path);
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <header className="header">
            <nav className="navbar">
                <ul className="menu">
                    <li className="find-gym-link"><Link to="/find-gym">Find a Gym</Link></li>
                </ul>
                {toggleSidebar && (
                    <button className="hamburger-button" onClick={toggleSidebar}>
                        {isSidebarOpen ? '✕' : '☰'}
                    </button>
                )}
                <div className="header-notifications">
                    <Link to="/notifications">
                        <FontAwesomeIcon icon={faBell} className="bell-icon" />
                        {unreadCount > 0 && <span className="notification-count">{unreadCount}</span>}
                    </Link>
                </div>
                <div className="header-profile-icon" onClick={toggleDropdown} ref={dropdownRef}>
                    {user && user.photoURL ? (
                        <img src={user.photoURL} alt="Profile" className="header-profile-pic" />
                    ) : (
                        <div className="header-placeholder-icon">👤</div>
                    )}
                    {isDropdownOpen && (
                        <ul className="dropdown-menu">
                            <li onClick={() => handleMenuClick(`/profile/${user?.uid}`)}>Profile</li>
                            <li onClick={() => handleMenuClick('/feed')}>Feed</li>
                            <li onClick={() => handleMenuClick('/about')}>About</li>
                            <li onClick={() => handleMenuClick('/contact')}>Contact</li>
                            {isAdmin && <li onClick={() => handleMenuClick('/admin-dashboard')}>Admin</li>}
                            <li onClick={handleLogout} className="logout-button">Logout</li>
                        </ul>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default Header;
