import { useState, useEffect, useCallback } from 'react';
import { addUserItem, getUserItems, updateUserItem, deleteUserItem, toggleLike } from '../services/firestoreService';
import { collection, addDoc, deleteDoc, doc, updateDoc, arrayUnion, arrayRemove, getDocs } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { serverTimestamp } from 'firebase/firestore';
import { addComment, addReply, deleteReply, deleteComment, getPostTypeRoot, fetchItemsWithReplies } from '../services/commentsService';

export const useComments = (currentUser, commentType, itemId, userId, collectionType) => {
  const [comments, setComments] = useState([]);

  const fetchCommentsAndReplies = useCallback(async () => {
    if (!userId || !collectionType) return;
    try {
      const postTypeRoot = getPostTypeRoot(userId, collectionType);
      console.log('Post Type Root:', postTypeRoot);
      const commentsRef = collection(firestore, postTypeRoot);
      const commentsSnapshot = await getDocs(commentsRef);
      const commentsData = commentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const commentsWithReplies = await Promise.all(commentsData.map(async (comment) => {
        const repliesRef = collection(firestore, `${postTypeRoot}/${comment.id}/replies`);
        const repliesSnapshot = await getDocs(repliesRef);
        const replies = repliesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('replies:', replies);
        return { ...comment, replies };
      }));
      setComments(commentsWithReplies);
    } catch (error) {
      console.error('Error fetching comments and replies:', error);
    }
  }, [userId, collectionType]);

  useEffect(() => {
    fetchCommentsAndReplies();
  }, [fetchCommentsAndReplies]);

  const handleAddComment = async (commentData) => {
    try {
        const newComment = await addComment(userId, collectionType, commentData);
        setComments(prevComments => [newComment, ...prevComments]);
        return newComment;
    } catch (error) {
        console.error('Error adding comment:', error);
        throw error;
    }
  };
  
  const handleAddReply = async (commentId, replyData, userId, commentType) => {
    console.log('handleAddReply params:', { 
      commentId, 
      replyData, 
      userId, 
      commentType,
      'replyData.userId': replyData.userId
    });
    try {
        if (!userId || !commentType || !commentId || !replyData) {
            console.error('Missing parameter:', {
              userId: !!userId,
              commentType: !!commentType,
              commentId: !!commentId,
              replyData: !!replyData
            });
            throw new Error('Missing required parameters for addReply');
        }
        const newReply = await addReply(userId, commentType, commentId, replyData);
        setComments(prevComments => 
            prevComments.map(comment => 
                comment.id === commentId 
                    ? { ...comment, replies: [...(comment.replies || []), newReply] }
                    : comment
            )
        );
        return newReply;
    } catch (error) {
        console.error('Error adding reply:', error);
        throw error;
    }
  };

  const handleDeleteComment = async (commentId, userId, collectionType) => {
    try {
      if (!userId || !collectionType || !commentId) {
        throw new Error('Missing required parameters for comment deletion');
      }
      await deleteComment(userId, collectionType, commentId);
      setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
      return true;
    } catch (error) {
      console.error('Error deleting comment:', error);
      return false;
    }
  };

  const handleToggleLike = async (commentId) => {
    try {
      const updatedComment = await toggleLike(userId, collectionType, commentId, currentUser.uid);
      setComments(prevComments => prevComments.map(comment => 
        comment.id === commentId ? updatedComment : comment
      ));
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const handleDeleteReply = async (commentId, replyId) => {
    try {
      if (!userId || !collectionType || !commentId || !replyId) {
        throw new Error('Missing required parameters for reply deletion');
      }
      await deleteReply(userId, collectionType, commentId, replyId);
      setComments(prevComments => prevComments.map(comment => 
        comment.id === commentId 
          ? { ...comment, replies: comment.replies.filter(reply => reply.id !== replyId) }
          : comment
      ));
    } catch (error) {
      console.error('Error deleting reply:', error);
      throw error;
    }
  };

  const refreshComments = async () => {
    try {
      const commentsData = await getUserItems(userId, collectionType);
      setComments(commentsData);
    } catch (error) {
      console.error('Error refreshing comments:', error);
    }
  };
  // Add other functions as needed (e.g., handleAddReply, handleDeleteReply)

  return { 
    comments, 
    handleAddComment, 
    handleDeleteComment, 
    handleToggleLike,
    handleDeleteReply,
    handleAddReply,
    refreshComments,
    setComments // Add this line
  };
};