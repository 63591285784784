import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../services/firebase.js';
import { followUser } from '../../../utils/userActions.js';

const NewUserFeedItem = ({ data }) => {
    const [user] = useAuthState(auth);

    return (
        <div className="feed-item">
            <div className="feed-item-header">
                <h3>New User: <Link to={`/profile/${data.uid}`}>{data.displayName || 'New User'}</Link></h3>
            </div>
            <p>{data.bio}</p>
            <div className="feed-item-footer">
                <Link to={`/profile/${data.uid}`} className="view-profile-button">View Profile</Link>
                <button className="follow-button" onClick={() => followUser(user.uid, data.uid)}>Follow</button>
            </div>
        </div>
    );
};

export default NewUserFeedItem;
