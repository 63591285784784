import React from 'react';
import '../../styles/layout/Sidebar.css';

const Sidebar = ({ isOpen, places, onSelectPlace, toggleSidebar, onUseCurrentLocation }) => {
    return (
        <div className={`sidebar ${isOpen ? 'open' : 'hidden'}`}>
            <button
                className="use-location-button"
                onClick={onUseCurrentLocation}
            >
                Use Current Location
            </button>
            <div className="placesList">
                <h3>Gyms Near You</h3>
                <ul>
                    {places.map((place) => (
                        <li key={place.place_id} className="placeItem" onClick={() => onSelectPlace(place)}>
                            {place.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
