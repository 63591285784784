import React from 'react';

const ReactionFeedItem = ({ data }) => {
    return (
        <div className="feed-item reaction-feed-item">
            <div className="feed-item-header">
                <h3>{data.displayName} reacted to a post!</h3>
            </div>
            <p>Reaction: {data.reactionType}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
        </div>
    );
};

export default ReactionFeedItem;
