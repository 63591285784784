import React, { useState, useEffect, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, getDoc, updateDoc, collection, setDoc, getDocs, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useParams, Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../../styles/UserProfile.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EditProfileModal from './EditProfileModal';
import InviteButton from './InviteButton';
import CommentsSection from './CommentsSection';
import EventLengthAlert from './EventLengthAlert';
import { getUserData } from '../../services/userProfileService';
import EventDetailsModal from "./EventDetailsModal";
import { useComments } from '../../hooks/useComments';

const UserProfile = () => {
    const { userId } = useParams();
    const { uid } = useParams();
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [futureEvents, setFutureEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [following, setFollowing] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [favoriteGyms, setFavoriteGyms] = useState([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    // const [comments, setComments] = useState([]);
    const [events, setEvents] = useState([]);
    const [showEventLengthAlert, setShowEventLengthAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
    const [eventSelection, setEventSelection] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [calendarStatus, setCalendarStatus] = useState('pending');
    const { comments, handleAddComment, handleAddReply, handleDeleteComment, handleDeleteReply } = useComments(user, 'user', userId, userId, `users/${userId}/comments`);    useEffect(() => {
        let isMounted = true;

        if (!user) return;

        const fetchProfile = async () => {
            const currentUserId = userId || user.uid;

            setProfile({});
            setFutureEvents([]);
            setPastEvents([]);
            setFollowing([]);
            setFollowers([]);
            setFavoriteGyms([]);
            setIsFollowing(false);

            if (currentUserId) {
                const userData = await getUserData(currentUserId);
                if (userData && isMounted) {
                    setProfile(userData);

                    const now = new Date();
                    const oneWeekAgo = moment().subtract(1, 'weeks').toDate();

                    const attending = Array.isArray(userData.attending) ? userData.attending : []; // Ensure attending is an array

                    const future = attending.filter(event => new Date(event.start.seconds * 1000) > now);
                    const past = attending.filter(event => new Date(event.end.seconds * 1000) < now && new Date(event.end.seconds * 1000) > oneWeekAgo);

                    const futureEventsWithLocation = await Promise.all(future.map(async (event) => {
                        if (!event.locationName) {
                            const locationDoc = await getDoc(doc(firestore, 'locations', event.locationId));
                            if (locationDoc.exists()) {
                                event.locationName = locationDoc.data().name;
                            }
                        }
                        return event;
                    }));

                    const pastEventsWithLocation = await Promise.all(past.map(async (event) => {
                        if (!event.locationName) {
                            const locationDoc = await getDoc(doc(firestore, 'locations', event.locationId));
                            if (locationDoc.exists()) {
                                event.locationName = locationDoc.data().name;
                            }
                        }
                        return event;
                    }));

                    setFutureEvents(futureEventsWithLocation);
                    setPastEvents(pastEventsWithLocation);

                    if (Array.isArray(userData.following)) {
                        const followingList = await Promise.all(
                            userData.following.map(async (followId) => {
                                const followDoc = await getDoc(doc(firestore, 'users', followId));
                                return followDoc.exists() ? { uid: followDoc.id, ...followDoc.data() } : null;
                            })
                        );
                        setFollowing(followingList.filter(follow => follow !== null));
                    }

                    if (Array.isArray(userData.followers)) {
                        const followersList = await Promise.all(
                            userData.followers.map(async (followerId) => {
                                const followerDoc = await getDoc(doc(firestore, 'users', followerId));
                                return followerDoc.exists() ? { uid: followerDoc.id, ...followerDoc.data() } : null;
                            })
                        );
                        setFollowers(followersList.filter(follower => follower !== null));
                    }

                    if (Array.isArray(userData.favorites)) {
                        const favoriteGymsData = await Promise.all(
                            userData.favorites.map(async (gymId) => {
                                const gymDoc = await getDoc(doc(firestore, 'locations', gymId));
                                return gymDoc.exists() ? { id: gymId, ...gymDoc.data() } : null;
                            })
                        );
                        setFavoriteGyms(favoriteGymsData.filter(gym => gym !== null));
                    }

                    if (userId && userData.followers && userData.followers.includes(user.uid)) {
                        setIsFollowing(true);
                    }

                    if (user.uid === currentUserId && !localStorage.getItem(`editProfileSeen_${currentUserId}`) && isMounted) {
                        setEditProfile(true);
                        localStorage.setItem(`editProfileSeen_${currentUserId}`, 'true');
                    }

                    // Fetch calendar request status
                    const calendarRequestDoc = await getDoc(doc(firestore, 'claimRequests', currentUserId));
                    if (calendarRequestDoc.exists()) {
                        const calendarRequestData = calendarRequestDoc.data();
                        setCalendarStatus(calendarRequestData.status || 'pending');
                    } else {
                        setCalendarStatus('none');
                    }
                } else {
                    console.error("No such user or failed to fetch user data!");
                }
            }
        };

        fetchProfile();

        return () => {
            isMounted = false;
        };
    }, [userId, user]);

    useEffect(() => {
        const fetchUserEvents = async () => {
            if (userId && calendarStatus === 'approved') {
                try {
                    const eventsCollection = collection(firestore, 'users', userId, 'userEvents');
                    const eventsSnapshot = await getDocs(eventsCollection);
                    const eventsList = eventsSnapshot.docs.map(doc => ({
                        id: doc.id,
                        title: doc.data().title,
                        start: doc.data().start.toDate(),
                        end: doc.data().end.toDate(),
                    }));
                    setEvents(eventsList);
                } catch (error) {
                    console.error('Error fetching user events:', error);
                }
            }
        };

        fetchUserEvents();
    }, [userId, calendarStatus]);

    const handleSaveEventDetails = async (details) => {
        if (!user || !eventSelection || isSubmitting) return;

        setIsSubmitting(true);

        const { startStr, endStr } = eventSelection;
        const eventDocRef = doc(collection(firestore, `users/${userId}/userEvents`));

        const eventData = {
            title: details.eventName,
            location: details.eventLocation,
            start: new Date(startStr),
            end: new Date(endStr),
            addedBy: {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
            },
            attendees: [user.uid],
        };

        try {
            await setDoc(eventDocRef, eventData);
            setEvents([...events, { ...eventData, id: eventDocRef.id }]);
        } catch (error) {
            console.error('Error saving event:', error);
        } finally {
            setIsSubmitting(false);
            setShowEventDetailsModal(false);
        }
    };

    const handleDateSelect = useCallback(
        (selectionInfo) => {
            const { startStr, endStr, start, end } = selectionInfo;
            const minDuration = 1 * 60 * 60 * 1000;
            if (end - start < minDuration) {
                setAlertMessage("Events must be at least 1 hour long.");
                setShowEventLengthAlert(true);
                return;
            }

            const hasOverlap = events.some(event =>
                start < event.end && end > event.start
            );
            if (hasOverlap) {
                setAlertMessage('Events cannot overlap.');
                setShowEventLengthAlert(true);
                return;
            }

            if (user) {
                setEventSelection({ startStr, endStr });
                setShowEventDetailsModal(true);
            } else {
                console.error('User not authenticated');
            }
        },
        [events, user]
    );

    const renderEventContent = (eventInfo) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {eventInfo.timeText} {eventInfo.event.title}
                </div>
            </div>
        );
    };

    const handleFollow = async () => {
        if (!user || user.uid === userId) return;

        const userRef = doc(firestore, 'users', user.uid);
        const followedUserRef = doc(firestore, 'users', userId);

        try {
            await updateDoc(userRef, {
                following: arrayUnion(userId)
            });

            await updateDoc(followedUserRef, {
                followers: arrayUnion(user.uid)
            });

            setIsFollowing(true);

        } catch (error) {
            console.error('Error following user:', error.message);
        }
    };

    const handleUnfollow = async () => {
        if (!user || user.uid === userId) return;

        const userRef = doc(firestore, 'users', user.uid);
        const followedUserRef = doc(firestore, 'users', userId);

        try {
            await updateDoc(userRef, {
                following: arrayRemove(userId)
            });

            await updateDoc(followedUserRef, {
                followers: arrayRemove(user.uid)
            });

            setIsFollowing(false);

        } catch (error) {
            console.error('Error unfollowing user:', error.message);
        }
    };

    const handleSaveProfile = async (updatedProfile) => {
        if (!user) return;

        const userRef = doc(firestore, 'users', user.uid);
        await updateDoc(userRef, updatedProfile);
        setProfile(updatedProfile);
        setEditProfile(false);
    };

    // Determine the banner class based on the user's belt rank
    const beltRankClass = `belt-rank-${profile.beltRank?.toLowerCase()}`;

    return (
        <div className="user-body-content">
            <div className={`user-profile-container ${beltRankClass}`}>
                <div className="user-about-card">
                    <div className="left-side">
                        <div className="user-profile-pic-container">
                            <img src={profile.photoURL || 'placeholder.png'} alt="Profile" className="user-profile-pic" />
                            <div className="stats-row">
                                {user && user.uid !== userId && (
                                    isFollowing ? (
                                        <button onClick={async () => {
                                            if (window.confirm("Are you sure you want to unfollow?")) {
                                                await handleUnfollow();
                                            }
                                        }} className="user-unfollow-button">Unfollow</button>
                                    ) : (
                                        <button onClick={handleFollow} className="user-follow-button">Follow</button>
                                    )
                                )}
                                <div className="stat-item">
                                    <span className="count">{futureEvents.length + pastEvents.length}</span>
                                    <span>Events</span>
                                </div>
                                <div className="stat-item">
                                    <span className="count">{followers.length}</span>
                                    <span>Followers</span>
                                </div>
                                <div className="stat-item">
                                    <span className="count">{following.length}</span>
                                    <span>Following</span>
                                </div>
                            </div>
                        </div>
                        <h3>About {profile.displayName}
                            {user && user.uid === userId && (
                                <button onClick={() => setEditProfile(true)} className="user-edit-button">
                                    <i className="fas fa-edit"></i> {/* Font Awesome Edit Icon */}
                                </button>
                            )}
                        </h3>
                        <p><strong>Hometown:</strong> {profile.city}, {profile.region}, {profile.country}</p>
                        <p><strong>Belt Rank:</strong> {profile.beltRank || 'Not Provided'}</p>
                        <p>{profile.bio || 'No bio available.'}</p>
                    </div>
                </div>
                <div className="user-favorite-gyms-card">
                    <h3>Favorite Gyms</h3>
                    {favoriteGyms.length > 0 ? (
                        <ul>
                            {favoriteGyms.map((gym) => (
                                <li key={gym.id}>
                                    <h4><Link to={`/gym/${gym.id}`}>{gym.name}</Link></h4>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No favorite gyms yet.</p>
                    )}
                </div>
                <div className="user-attending-events user-events-card">
                    <div className="events-column">
                        <h3>Upcoming Events</h3>
                        {futureEvents.length > 0 ? (
                            <ul className="no-bullets">
                                {futureEvents.map((event) => (
                                    <li key={`${event.eventId}-${event.start.seconds}`} className="user-event-item">
                                        <div className="user-event-info">
                                            <span className="user-event-details">
                                                <strong>{event.title}</strong> at <em><Link
                                                to={`/gym/${event.locationId}`}>{event.locationName || 'Unknown Location'}</Link></em>
                                                <br />
                                                {moment(event.start.seconds * 1000).format('MMMM Do YYYY, h:mm a')} - {moment(event.end.seconds * 1000).format('h:mm a')}
                                                <br />
                                                <Link to={`/gym/${event.locationId}/event/${event.eventId}`}>View Event</Link>
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No upcoming events</p>
                        )}
                    </div>
                    <div className="events-column">
                        <h3>Past Events</h3>
                        {pastEvents.length > 0 ? (
                            <ul className="no-bullets">
                                {pastEvents.map((event) => (
                                    <li key={`${event.eventId}-${event.start.seconds}`} className="user-event-item">
                                        <div className="user-event-info">
                                            <span className="user-event-details">
                                                <strong>{event.title}</strong> at <em><Link
                                                to={`/gym/${event.locationId}`}>{event.locationName || 'Unknown Location'}</Link></em>
                                                <br />
                                                {moment(event.start.seconds * 1000).format('MMMM Do YYYY, h:mm a')} - {moment(event.end.seconds * 1000).format('h:mm a')}
                                                <br />
                                                <Link to={`/gym/${event.locationId}/event/${event.eventId}`}>View Event</Link>
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No past events</p>
                        )}
                    </div>
                </div>
                <CommentsSection
                    uniqueId={uid}
                    userId={userId}
                    collectionType={`users/${userId}/comments`}
                    comments={comments}
                    currentUser={user}
                    pageType="profile"
                    handleAddComment={handleAddComment}
                    handleAddReply={handleAddReply}
                    handleDeleteComment={handleDeleteComment}
                    handleDeleteReply={handleDeleteReply}
                    commentType="user" 
                    itemId={userId}
                />
                {/* Conditionally render the calendar section */}
                {calendarStatus === 'approved' && (
                    <div className="user-calendar-container">
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            height="auto"
                            initialView="timeGridDay"
                            selectable={true}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'timeGridDay,timeGridWeek'
                            }}
                            views={{
                                timeGridDay: {
                                    buttonText: 'Day'
                                },
                                timeGridWeek: {
                                    buttonText: 'Week'
                                }
                            }}
                            events={events}
                            eventContent={renderEventContent}
                            slotMinTime="07:00:00"
                            slotMaxTime="22:00:00"
                            allDaySlot={false}
                            selectMirror={true}
                            select={handleDateSelect}
                            eventClick={({ event }) => {
                                navigate(`/profile/${userId}/event/${event.id}`);
                            }}
                        />
                    </div>
                )}
                <div className="user-following-card">
                    <div className="user-following-header">
                        <h3>Following ({following.length})</h3>
                        <InviteButton inviteType="app" />
                    </div>
                    {following.length > 0 ? (
                        <ul className="user-following-list">
                            {following.map((follow) => (
                                <li key={follow.uid} className="user-following-item">
                                    <Link to={`/profile/${follow.uid}`}>
                                        <div className="user-following-content">
                                            <img src={follow.photoURL || 'placeholder.png'} alt="Following"
                                                 className="user-following-pic" />
                                            <span className="user-following-name">{follow.displayName}</span>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>Not following anyone yet.</p>
                    )}
                </div>
                <div className="user-followers-card">
                    <h3>Followers ({followers.length})</h3>
                    {followers.length > 0 ? (
                        <ul className="user-following-list">
                            {followers.map((follower) => (
                                <li key={follower.uid} className="user-followers-item">
                                    <Link to={`/profile/${follower.uid}`}>
                                        <div className="user-following-content">
                                            <img src={follower.photoURL || 'placeholder.png'} alt="Follower"
                                                 className="user-followers-pic" />
                                            <span className="user-followers-name">{follower.displayName}</span>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No followers yet.</p>
                    )}
                </div>
            </div>
            {editProfile && (
                <EditProfileModal
                    profile={profile}
                    onSave={handleSaveProfile}
                    onClose={() => setEditProfile(false)}
                />
            )}
            {showEventLengthAlert && (
                <EventLengthAlert
                    message={alertMessage}
                    onClose={() => setShowEventLengthAlert(false)}
                />
            )}
            {/* {showEventDetailsModal && (
                <EventDetailsModal
                    isOpen={showEventDetailsModal}
                    onClose={() => setShowEventDetailsModal(false)}
                    onSave={handleSaveEventDetails}
                />
            )} */}
        </div>
    );
};

export default UserProfile;