import React from 'react';

const UserTable = ({ users }) => {
    return (
        <div className="user-table">
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Events</th>
                    <th>Favorites</th>
                    <th>Comments</th>
                    <th>Replies</th>
                    <th>Reactions</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                    <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.events}</td>
                        <td>{user.favorites}</td>
                        <td>{user.comments}</td>
                        <td>{user.replies}</td>
                        <td>{user.reactions}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserTable;
