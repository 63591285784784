import React from 'react';
import { Link } from 'react-router-dom';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share';
import Countdown from 'react-countdown';

const EventFeedItem = ({ data, gymId, gymName, attendees }) => {
    const eventDate = new Date(data.start);

    return (
        <div className="feed-item">
            <div className="feed-item-left">
                <div className="feed-item-header">
                    <h3>Event: {data.title}</h3>
                    <p>Hosted by: <Link to={`/gym/${gymId}`}>{gymName}</Link></p>
                    <Countdown date={eventDate} />
                </div>
                <div className="feed-item-body">
                    <p>{data.description}</p>
                    <p>Attendees: {attendees.length}</p>
                </div>
            </div>
            <div className="feed-item-right">
                <FacebookShareButton url={window.location.href} quote={data.title}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={data.title}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href} title={data.title}>
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div>
        </div>
    );
};

export default EventFeedItem;
